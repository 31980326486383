import loader from '../assets/images/loader.gif';

const Loader = () => {
  const styles = {
    width: '4rem',
    height: '4rem',
    backgroundImage: `url("${loader}")`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgrounPosition: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    pointerEvents: 'none',
    transition: 'opacity 300ms ease',
  };

  return <div className="loader" style={styles} />;
};

export default Loader;
