import { makeStyles } from '@material-ui/core';
import Color from 'color';

const useStyles = makeStyles((theme) => {
  const color = Color(theme.iconColor);
  const lightness = color.lightness();
  const iconColorLuminosity = color.luminosity();

  let darkerLeftThumbAndNail;
  let brighterLeftThumbAndNail;

  let darkerRightAndCenterFingers;
  let brighterRightAndCenterFingers;

  // Lumiosity controls the brightness of color

  if (iconColorLuminosity <= 0.5) {
    brighterLeftThumbAndNail = color.lightness(lightness + 25).hex();
    brighterRightAndCenterFingers = color.lightness(lightness + 15).hex();
    darkerLeftThumbAndNail = color.lightness(lightness - 5).hex();
    darkerRightAndCenterFingers = color.lightness(lightness - 20).hex();
  } else {
    brighterLeftThumbAndNail = color.lightness(lightness + 20).hex();
    brighterRightAndCenterFingers = color.lightness(lightness + 10).hex();
    darkerLeftThumbAndNail = color.lightness(lightness - 10).hex();
    darkerRightAndCenterFingers = color.lightness(lightness - 15).hex();
  }

  const rightThumbAndCenterOfFingerColor = color.isLight()
    ? darkerRightAndCenterFingers
    : brighterRightAndCenterFingers;
  const fingerNailAndLeftThumbColor = color.isLight()
    ? darkerLeftThumbAndNail
    : brighterLeftThumbAndNail;

  return {
    hand: {
      width: '25%',
      minWidth: '100%',
      textAlign: 'center',
      padding: '0',
    },
    thumb: {
      display: 'inline-block',
      width: '1em',
      height: '1em',
      backgroundColor: theme.handColor
        ? rightThumbAndCenterOfFingerColor
        : '#00a5c2',
      margin: '1em .65em 0 .25em',
      borderLeft: `.5em solid ${
        theme.handColor ? fingerNailAndLeftThumbColor : '#00bfe0'
      }`,
      borderRadius: '25% 15% 15% 40%',
    },
    finger: {
      display: 'inline-block',
      width: '1em',
      height: '1.5em',
      backgroundColor: theme.handColor
        ? rightThumbAndCenterOfFingerColor
        : '#00a5c2',
      margin: ' 0 .15em',
      borderBottom: `.5em solid ${
        theme.handColor ? fingerNailAndLeftThumbColor : '#00bfe0'
      }`,
      borderLeft: `.25em solid ${
        theme.handColor ? theme.handColor : '#006F83'
      }`,
      borderRadius: '15% 15% 25% 25%',
      animationName: '$tap',
      animationDuration: '1s',
      animationIterationCount: 'infinite',
    },
    fingerOne: {
      animationDelay: '.8s',
    },
    fingerTwo: {
      animationDelay: '.7s',
    },
    fingerThree: {
      animationDelay: '.6s',
    },
    fingerFour: {
      animationDelay: '.5s',
    },

    '@keyframes tap': {
      '0%': {
        transform: 'translateY(0)',
      },

      '60%': {
        transform: 'translateY(-1em)',
      },

      '70%': {
        transform: 'translateY(0)',
      },
      '100%': {
        transform: 'translateY(0)',
      },
    },
  };
});

export default function HandLoader() {
  const styles = useStyles();

  return (
    <div className={styles.hand}>
      <div className={styles.thumb} />
      <div className={`${styles.finger} ${styles.fingerOne}`} />
      <div className={`${styles.finger} ${styles.fingerTwo}`} />
      <div className={`${styles.finger} ${styles.fingerThree}`} />
      <div className={`${styles.finger} ${styles.fingerFour}`} />
    </div>
  );
}
