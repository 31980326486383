import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';

export const UnsizedButton = withStyles((theme) => ({
  root: {
    color: theme.textButtonColor,
    backgroundColor: theme.secondaryColor,
    '&:hover': {
      backgroundColor: theme.secondaryColor,
      opacity: '0.7',
    },
  },
}))(Button);

export default (props) => {
  return <UnsizedButton {...props} size="large" />;
};
