/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Suspense, Fragment, ReactNode } from 'react';
import { Switch, Route } from 'react-router-dom';
import _ from 'lodash';
import { LoadingScreen } from '../components';
import { nest } from 'recompose';


export type Routes = {
  loading?: boolean;
  exact?: boolean;
  path?: string | string[];
  guard?: React.ComponentType<any> | Array<React.ComponentType<any>> | ReactNode | Array<ReactNode>;
  context?: React.ComponentType<any> | Array<React.ComponentType<any>> | ReactNode | Array<ReactNode>;
  contextProps?: string[],
  layout?: any;
  component?: any;
  routes?: Routes;
}[];

export const renderRoutes = (
  routes: Routes = [],
  props = {},
  componentProps = [],
  loading = true
): JSX.Element => (
  <Suspense fallback={loading && <LoadingScreen />}>
    <Switch>
      {
        routes.map((route, i) => {
          const Guard = route.guard ? nest(...(_.castArray(route.guard) as Array<React.ComponentType<any>>)) : Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component || Fragment;
          const Context = (route.context) ? nest(...(_.castArray(route.context) as Array<React.ComponentType<any>>)) : Fragment;
          const contextPropKeys = _.get(route, 'contextProps', []);
          const ctxProps = (contextPropKeys && _.isArray(contextPropKeys)) ? _.pick(props, contextPropKeys) : {};

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={(iprops) => (
                <Context {...ctxProps}>
                  <Guard>
                    <Layout>
                      {route.routes
                        ? renderRoutes(route.routes)
                        : (
                          <Component
                            {...iprops}
                            {..._.omit(props, _.difference(contextPropKeys, componentProps))}
                          />
                        )}
                    </Layout>
                  </Guard>
                </Context>
              )}
            />
          );
        })
      }
    </Switch>
  </Suspense>
);
