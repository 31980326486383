import './equipment-check-v2.module.scss';

import { useCustomColors, useQuery } from '@enotarylog/shared';
import EquipmentCheckModule from './components/equipment-check';
import { useState } from 'react';
import { makeStyles } from '@material-ui/styles';

export interface EquipmentCheckV2Props {
  setActiveNextButton: (value: boolean) => void;
  setShowNextButton: (value: boolean) => void;
  customBackground: string;
}

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
  },
  iframe: {
    height: '615px',
    width: '100%',
  },
}));

const FACE_RECOGNITION_MODELS_URL =
  'https://storage.googleapis.com/enl-public-files/public/static/models';
const CDN_URL = 'https://storage.googleapis.com/enl-public-files/public';

export const EquipmentCheckV2: React.FC<EquipmentCheckV2Props> = (props) => {
  const classes = useStyles();
  const { primaryColor = '', secondaryColor = '', background = '' } = useCustomColors();

  const [resetSteps, setResetSteps] = useState(false);
  const [activeNextButton, setActiveNextButton] = useState(false);
  const [showNextButton, setShowNextButton] = useState(false);
  const handleRestSteps = (value: boolean) => {
    setResetSteps(value);
    setShowNextButton(false);
  };
  const handleOnSuccess = () => setShowNextButton(true);
  const handleOnComplete = () => {
    // Only emit super safe info that can safely be leaked to anyone, like "equipment-check-passed", not any PII or ids that could be used for anything interesting.
    // If you need to emit something more interesting, don't use the origin '*'
    setTimeout(() => {
      if (window.parent) {
        window.parent.postMessage('equipment-check-passed', '*');
      }
    }, 3000); 
  };
  const nsId = useQuery('nsId');

  return (
    <div className={classes.root}>
      <EquipmentCheckModule
        className={classes.iframe}
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
        background={background}
        resetSteps={resetSteps}
        setResetSteps={handleRestSteps}
        cdnUrl={CDN_URL}
        customBackground={props.customBackground}
        modelsUrl={FACE_RECOGNITION_MODELS_URL}
        onComplete={handleOnComplete}
        onSuccess={handleOnSuccess}
      />
    </div>
  );
};

export default EquipmentCheckV2;
