import { setupWorker } from 'msw'
import { getHandlers } from './handlers'
import { consumerHandlers } from './handlers-consumer'

// This configures a Service Worker with the given request handlers.
export const getWorker = (isNotary = false, requestId: string, nsId: string) => {
  if (!isNotary) {
    const handlers = consumerHandlers(requestId, nsId);
    return setupWorker(...handlers);
  } else {
    const handlers = getHandlers(requestId, nsId);
    return setupWorker(...handlers);
  }
}
