// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { EnlWebviewerInstance } from '@enotarylog/webviewer';
import Promise from 'bluebird';
import _ from 'lodash';
const license = process.env.NX_PDFTRON_LICENSE;

const EMPTY_ANNOT_COMMAND = `<?xml version="1.0" encoding="UTF-8" ?><xfdf xmlns="http://ns.adobe.com/xfdf/" xml:space="preserve"><fields /><add /><modify /><delete /></xfdf>`;

/**
 * Given an array of objects that contains the annot command and the annot id,
 * this function will generate the merged xfdf string without instantiating a new
 * AnnotationManager instance or loading the pdf using WebviewerInstance
 * The returned xfdf will exclude all tag annotations and/or hidden
 * annotations which are not displayed in the webviewer
 */
export function getGenerateXfdfFromAnnotComands(instance: EnlWebviewerInstance) {
  return async (annotCommands: Array<{ id: string, xfdf: string }>) => {
    // make sure resources are cleaned up
    return instance.PDFNet.runWithCleanup(async () => {
      const fdf = await instance.PDFNet.FDFDoc.createFromXFDF(EMPTY_ANNOT_COMMAND);

      try {

        await Promise.map(annotCommands, async (a) => {
          try {
            await fdf.mergeAnnots(a.xfdf)
          }
          catch(err) {
            console.error(`failed to merge xfdf for id ${a.id}`)
          }
        }, { concurrency: 1 });

        return fdf.saveAsXFDFAsString() as Promise<string>
      } catch(err) {
        console.error(err);
        return fdf.saveAsXFDFAsString() as Promise<string>
      }
    }, license) as Promise<string>;
  }
}
