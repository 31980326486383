// eslint-disable-next-line import/no-unresolved
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ReusableButton from '../equipment-check/reusableButton';
import VerficationDefaultIcon from '../equipment-check/icons/verificationDefaultIcon';

import { AudioContext } from '../../utils/WebRTC/Variables';
import AudioVisualizer from './audio-visualizer';

const styles = (theme) => ({
  panelList: {
    flex: 1,
  },
  headerStyle: {
    margin: '1rem 0 0',
    fontSize: '40px',
    fontWeight: '400',
    textAlign: 'center',
    color: theme.headerTextColor,
    lineHeight: 1,
  },
});

const defaultMinMicThreshold = 50;
const defaultMaxMicThreshold = 140;
const defaultMessageOnTicks = 200;
const defaultSoundForTicksThreshold = 30;

class AudioAnalyser extends Component {
  constructor(props) {
    super(props);
    this.soundForTicks = 0;
    this.ticks = 0;

    this.state = {
      audioData: new Uint8Array(0),
    };
  }

  componentDidMount() {
    if (this.props.flags?.bypassMicCheck) {
      this.props.onSuccess();

      return;
    }

    // this.visualizerHeader = 'WE CANT HEAR YOU!';
    this.vizualizerText = 'Sing us your favorite song so we can hear you!';
    this.visualizerHeader = 'Sing us a song!';
    this.audioDetected = false;
    this.audioContext = new AudioContext();

    // To extract data from an audio source, we need an "AnalyserNode"
    this.analyser = this.audioContext.createAnalyser();

    // To store the "waveform" data that the "AnalyserNode" will be creating
    this.dataArray = new Uint8Array(this.analyser.frequencyBinCount);

    // We passed the media stream from the microphone into the component as a prop
    // and turn it into a source for the "Web Audio API"
    const isMediaElementSource = this.props.audio instanceof HTMLMediaElement;

    this.source = isMediaElementSource
      ? this.audioContext.createMediaElementSource(this.props.audio)
      : this.audioContext.createMediaStreamSource(this.props.audio);

    // We use "GainNodes" to control the volume
    this.gainNode = this.audioContext.createGain();
    this.gainNode.gain.value = '0';
    // once we have the source we can then connect the analyser.
    this.source.connect(this.analyser);
    this.source.connect(this.gainNode);
    this.gainNode.connect(this.audioContext.destination);

    // We kick off the animation loop after we connect the source to the analyser
    this.rafId = requestAnimationFrame(this.tickHandler);
  }

  componentWillUnmount() {
    // To release all the resources if we remove the component
    cancelAnimationFrame(this.rafId);

    if (this.analyser) {
      this.analyser.disconnect();
    }

    if (this.source) {
      this.source.disconnect();
    }
  }

  tickHandler = () => {
    const soundForTicksThreshold =
      this.props?.flags?.soundForTicksThreshold ||
      defaultSoundForTicksThreshold;
    // const soundForTicksThreshold = 1000000000000;

    const messageOnTicks =
      this.props?.flags?.messageOnTicks || defaultMessageOnTicks;

    this.ticks += 1;
    // We call this to update the visualization
    this.analyser.getByteTimeDomainData(this.dataArray);

    // We need to copy the current waveform as an array of integers
    this.setState({ audioData: this.dataArray });

    // Do some calculations on the waveform data array to determine if they meet the thresholds for a working mic
    this.min = Math.min(...this.dataArray);
    this.max = Math.max(...this.dataArray);
    this.avg =
      this.dataArray.reduce((a, b) => a + b, 0) / this.dataArray.length;

    if (this.soundForTicks >= soundForTicksThreshold) {
      this.visualizerHeader = 'We hear you!';
      this.vizualizerText = '';
      this.audioDetected = true;
    } else if (!this.audioToQuiet(this.min, this.max)) {
      this.soundForTicks += 1;
    } else if (!this.audioDetected && this.ticks >= messageOnTicks) {
      if (!this.audioDetected && !this.cantHearMessageSet) {
        this.visualizerHeader = 'We cannot hear you!';
        this.vizualizerText =
          'Make sure you are unmuted, eNotaryLog has access to your microphone and you have the right microphone selected above.';
        this.cantHearMessageSet = true;
        this.props.onFail('microphone');
      }
    }

    // Since we'll be animating this visualization, we'll call upon the browser's
    // "requestAnimationFrame" API to pull the latest audio data from the "AnalyserNode"
    // every time we want to update the visualization.
    this.rafId = requestAnimationFrame(this.tickHandler);
  };

  audioToQuiet(min, max) {
    const minMicThreshold =
      this.props.flags?.minMicThreshold || defaultMinMicThreshold;
    const maxMicThreshold =
      this.props.flags?.maxMicThreshold || defaultMaxMicThreshold;

    return minMicThreshold < min && max < maxMicThreshold;
  }

  resetComponent() {
    this.visualizerHeader = 'Sing us a song!';
    this.vizualizerText = 'Sing us your favorite song so we can hear you!';
    this.audioDetected = false;
    this.cantHearMessageSet = false;
    this.soundForTicks = 0;
    this.ticks = 0;
  }

  render() {
    const { classes } = this.props;

    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {this.audioDetected ? (
          <Grid
            container
            item
            xs={12}
            className={classes.panelList}
            alignItems="flex-end"
            style={{ margin: 'auto', height: '100%', width: '100%' }}
          >
            {/* <Grid
              container
              justify='space-evenly'
              alignItems='center'
              style={{ margin: 'auto' }}
            > */}
            <Grid
              container
              item
              xs={6}
              alignItems="center"
              justifyContent="space-evenly"
              style={{
                width: '50%',
                height: '100%',
                flexDirection: 'column',
                display: 'flex',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                padding: '1rem 1rem 2rem 1rem',
              }}
            >
              <Grid item >
                <h2 className={classes.headerStyle}>{this.visualizerHeader}</h2>
              </Grid>
              <Grid item className={classes.btnContainer} >
                <ReusableButton
                  onClick={(evt) => {
                    evt.preventDefault();

                    return this.props.onSuccess(evt);
                  }}
                  type="button"
                  variant="contained"
                  theme={this.props.theme}
                  data-trigger="sound-test"
                  endIcon={<ArrowForwardIosIcon />}
                >
                  Keep going!
                </ReusableButton>
              </Grid>
            </Grid>
            <Grid
              container
              item
              alignItems="center"
              style={{ width: '50%', height: '100%', padding: '1rem' }}
              justify="center"
              xs={6}
            >
              <VerficationDefaultIcon
                length={160}
                theme={this.props.theme}
                animation="middle-out"
              />
            </Grid>
            {/* </Grid> */}
          </Grid>
        ) : (
          <>
            <Grid container item xs={12} className={classes.panelList}>
              {this.visualizerHeader && (
                <Grid
                  container
                  item
                  xs={12}
                  sm={12}
                  style={{ alignItems: 'center', justifyContent: 'center' }}
                >
                  <Grid container item xs={12} sm={12} justify="center">
                    <h2
                      className={classes.headerStyle}
                      style={{
                        margin: '0',
                        marginTop: '0',
                        fontSize: '40px',
                        fontWeight: '400',
                        paddingTop: '1rem',
                      }}
                    >
                      {this.visualizerHeader}
                    </h2>
                  </Grid>
                </Grid>
              )}
              <Grid item xs={12} sm={12} margin justify="center">
                <p
                  style={{
                    textAlign: 'center',
                    margin: '1rem auto',
                    paddingLeft: '1rem',
                    paddingRight: '1rem',
                    fontSize:
                      this.vizualizerText && this.vizualizerText.length > 100
                        ? 'large'
                        : 'x-large',
                    fontWeight: '300',
                  }}
                >
                  {this.vizualizerText}
                </p>
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              className={classes.panelList}
              alignItems="flex-end"
              style={{ marginBottom: '3rem' }}
            >
              <Grid
                container
                item
                xs={12}
                style={{ padding: '0' }}
                justify="center"
              >
                <AudioVisualizer
                  audioData={this.state.audioData}
                  theme={this.props.theme}
                />
              </Grid>
            </Grid>
          </>
        )}
      </>
    );
  }
}

AudioAnalyser.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  audio: PropTypes.object,
  onFail: PropTypes.func.isRequired,
};

AudioAnalyser.defaultProps = {
  audio: PropTypes.object,
};

export default withStyles(styles)(AudioAnalyser);
