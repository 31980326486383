/* eslint-disable react/no-unescaped-entities */
// eslint-disable-next-line import/no-unresolved
import React, { useRef, useState } from 'react';
import { detect } from 'detect-browser';
import PropTypes from 'prop-types';
import $ from 'jquery';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import RefreshIcon from '@material-ui/icons/Refresh';
import ReusableButton from './reusableButton';
import BrowserLoader from './browser-loader/browerLoader';
import {
  isHostReachable,
  getNetworkDownloadSpeedTest,
} from '../../utils/Network';

// const defaultDownloadTimeThreshold = 1;
const defaultStepTransitionTimeout = 5000;
const defaultCheckDelayTimeout = 3000;
// const defaultFileDownloadUrl = 'https://enotarylog.com/static/pdf/esign_policy.pdf';
const defaultMinimumCompatibleVersions = {
  firefox: 79,
  chrome: 84,
  safari: 13,
  'edge-chromium': 10,
};

const defaultDisableChromeCheck = false;
const defaultDisableFirefoxCheck = false;
const defaultDisableSafariCheck = false;

const useStyles = makeStyles((theme) => ({
  '@keyframes tracking-in-expand-fwd': {
    '0%': {
      letterSpacing: '-0.5em',
      transform: 'translateZ(-700px)',
      opacity: '0',
    },
    '40%': {
      opacity: '0.6',
    },
    '100%': {
      transform: 'translateZ(0)',
      opacity: '1',
    },
  },
  '@keyframes text-focus-in': {
    '0%': {
      filter: 'blur(12px)',
      opacity: '0',
    },
    '100%': {
      filter: 'blur(0px)',
      opacity: '1',
    },
  },
  headerStyle: {
    textAlign: 'center',
    color: theme.headerTextColor,
    marginTop: '0',
    fontSize: '40px',
    fontWeight: '400',
  },
  root: {
    padding: 0,
  },
  textStyle: {
    textAlign: 'center',
  },
  headerContainer: {
    height: '33.33%',
  },
  pStyle: {
    textAlign: 'center',
    fontSize: 'x-large',
    fontWeight: '300',
  },
  recommendedHeader: {
    textAlign: 'center',
    fontSize: 'x-large',
    fontWeight: 'bold',
  },
  btnContainer: {
    height: '60px',
  },
  mainContainer: {
    animation: 'text-focus-in 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both',
    animationName: '$text-focus-in',
  },
  table: {
    minWidth: 500,
  },
  tableCellRoot: {
    padding: '0',
  },
  tableStyle: {
    borderCollapse: 'collapse',
    width: '100%',
    fontSize: 'medium',
    fontWeight: '400',
  },
  tableRowStyle: {},
  tableHeaderStyle: {
    border: '1px solid #ddd',
    padding: '8px',
    textAlign: 'left',
    backgroundColor: theme.primaryColor,
    color: 'white',
  },
  tableDetailStyle: {
    border: '1px solid #ddd',
    padding: '8px',
  },
}));

const deviceAndBrowser = detect();

// async function getNetworkDownloadSpeed(downloadTimeThreshold, fileDownloadUrl) {
//   const threshold = downloadTimeThreshold || defaultDownloadTimeThreshold;
//   // TODO: Implement a better network download speed test either with a package or a more robust implmentation
//   // TODO: maybe use a different larger file?
//   const baseUrl = fileDownloadUrl || defaultFileDownloadUrl;
//   const startTime = new Date().getTime();

//   // TODO: Set timeout for this fetch set to expire at the speed threshold or use axios here
//   // NOTE: not sure how reliable of a metric this is since its fetching a pdf file stream and not a whole file chunk.
//   // but throttling does mimic the load we might expect on slower networks
//   await fetch(baseUrl);

//   const endTime = new Date().getTime();

//   const totalTimeInMs = endTime - startTime;

//   if (totalTimeInMs / 1000 > threshold) {
//     throw Error('download too slow');
//   }
// }

// async function getNetworkUploadSpeed() {
//   // TODO: find a reliable way to test for upload speed
// }

const FailureText = ({ failureReason, minCompatibleVersions }) => {
  if (failureReason === 'network') {
    return (
      <p
        style={{
          padding: '1rem',
          textAlign: 'center',
          fontSize: 'x-large',
          fontWeight: '300',
        }}
      >
        It appears your internet connection speed could be problematic during
        your session - please be aware this may cause some issues.
      </p>
    );
  }

  if (failureReason === 'device') {
    return (
      <p
        style={{
          padding: '1rem',
          textAlign: 'center',
          fontSize: 'x-large',
          fontWeight: '300',
        }}
      >
        It appears the device you are using is not compatible with our system.
        Please move to a computer device which is supported.
      </p>
    );
  }

  if (failureReason === 'browser') {
    return (
      <>
        <Grid item xs={12}>
          <p style={{ margin: '0', textAlign: 'center' }}>
            It appears the browser you picked is not compatible with our system.
          </p>
        </Grid>
        <Grid item xs={12}>
          <p style={{ margin: '0', textAlign: 'center' }}>
            Please use either of the below browsers:
          </p>
        </Grid>
        <Grid container item justify="space-evenly" xs={12}>
          <Grid item>
            <a href="https://www.mozilla.org/en-US/firefox/new/">
              <p>Firefox {minCompatibleVersions.firefox} or newer</p>
            </a>
          </Grid>
          <Grid item>
            <a href="https://www.google.com/chrome/">
              <p>Chrome {minCompatibleVersions.chrome} or newer</p>
            </a>
          </Grid>
          <Grid item>
            <a href="https://support.apple.com/downloads/safari">
              <p>Safari {minCompatibleVersions.safari} or newer</p>
            </a>
          </Grid>
        </Grid>
      </>
    );
  }

  return <></>;
};

FailureText.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  minCompatibleVersions: PropTypes.object.isRequired,
  failureReason: PropTypes.string.isRequired,
};

const BrowserStep = ({ onSuccess, onFail, flags, theme, customBackground }) => {
  const browserRef = useRef(null);
  const minCompatibleVersions =
    flags?.minimumCompatibleVersions || defaultMinimumCompatibleVersions;
  const stepTransitionTimeout =
    flags?.stepTransitionTimeout || defaultStepTransitionTimeout;
  const disableChromeCheck = _.isNil(flags?.disableChrome)
    ? defaultDisableChromeCheck
    : flags?.disableChrome;
  const disableSafariCheck = _.isNil(flags?.disableSafari)
    ? defaultDisableSafariCheck
    : flags?.disableSafari;
  const disableFirefoxCheck = _.isNil(flags?.disableFirefox)
    ? defaultDisableFirefoxCheck
    : flags?.disableFirefox;
  const checkDelayTimeout =
    flags?.checkDelayTimeout || defaultCheckDelayTimeout;
  const [currentTest, setCurrentTest] = useState(null);
  const [componentHeaderText, setComponentHeaderText] = useState(
    'CHECKING YOUR SIDE!'
  );
  const [failureReason, setFailureReason] = useState(null);

  const handleNetworkCheck = async (evt) => {
    const testName = 'network';

    setCurrentTest(testName);
    setTimeout(async () => {
      try {
        if (flags?.bypassNetworkCheck) {
          return onSuccess(evt, 'device');
        }

        setComponentHeaderText('ALMOST DONE!');
        setCurrentTest(testName);

        await getNetworkDownloadSpeedTest();
        await isHostReachable();
        // await getNetworkDownloadSpeed(flags?.downloadTimeThreshold, flags?.fileDownloadUrl); // TODO: Verify if this function is needed
        // await getNetworkUploadSpeed();

        setCurrentTest('network-completed');
        setTimeout(() => {
          setComponentHeaderText('EVERYTHING LOOKS GOOD HERE!');
          setCurrentTest('completed');
        }, 1000);

        return setTimeout(() => {
          return onSuccess(evt, 'device');
        }, stepTransitionTimeout);
      } catch (error) {
        error.data = {
          deviceAndBrowser,
        };
        setFailureReason(testName);

        return onFail(testName, error);
      }
    }, checkDelayTimeout);
  };

  const handleBrowserCheck = (evt) => {
    const testName = 'browser';

    try {
      if (disableChromeCheck === true) {
        setFailureReason(testName);
        const error = new Error('Chrome is incompatible ');

        onFail(testName, error);

        return;
      }

      if (disableSafariCheck === true) {
        setFailureReason(testName);
        const error = new Error('Safari is incompatible ');

        onFail(testName, error);

        return;
      }

      if (disableFirefoxCheck === true) {
        setFailureReason(testName);
        const error = new Error('Firefox is incompatible ');

        onFail(testName, error);

        return;
      }

      const minCompatibleVersions =
        flags?.minimumCompatibleVersions || defaultMinimumCompatibleVersions;

      if (flags?.bypassBrowserCheck) {
        handleNetworkCheck(evt);

        return;
      }

      setCurrentTest(testName);

      setTimeout(() => {
        const browserName = (deviceAndBrowser && deviceAndBrowser.name) || null;
        let browserVersion =
          (deviceAndBrowser && deviceAndBrowser.version) || null;

        browserVersion = browserVersion ? browserVersion.split('.')[0] : null;

        if (
          browserName in minCompatibleVersions &&
          +browserVersion >= minCompatibleVersions[browserName]
        ) {
          handleNetworkCheck(evt);
        }
      }, checkDelayTimeout);
    } catch (error) {
      setTimeout(() => {
        const error = new Error('browser incompatible');

        error.data = {
          deviceAndBrowser,
        };
        setFailureReason(testName);

        onFail(testName, error);
      }, checkDelayTimeout);
    }
  };

  const handleOsDetection = (evt) => {
    const testName = 'device';

    if (flags?.bypassDeviceCheck) {
      handleBrowserCheck(evt);

      return;
    }

    setCurrentTest(testName);

    setTimeout(() => {
      const currentOs = deviceAndBrowser
        ? deviceAndBrowser.os.toLowerCase().replace(' ', '')
        : null;

      if (
        currentOs.includes('macos') ||
        currentOs.includes('linux') ||
        currentOs.includes('windows')
      ) {
        handleBrowserCheck(evt);

        return;
      }

      const error = new Error('device incompatible');

      error.data = {
        deviceAndBrowser,
      };
      setFailureReason(testName);

      onFail(testName, error);
    }, checkDelayTimeout);
  };

  const handleStartCheck = (evt) => {
    evt.persist();
    $(browserRef.current).children('.test-internet').removeClass('hide-loader');
    $(browserRef.current)
      .children('.test-internet-hidden')
      .removeClass('show-message');
    setTimeout(() => {
      handleOsDetection(evt);
      $(browserRef.current).children('.test-internet').addClass('hide-loader');
      $(browserRef.current)
        .children('.test-internet-hidden')
        .addClass('show-message');
    }, 1000);
  };

  const classes = useStyles();

  return (
    <Grid
      container
      item
      xs={12}
      ref={browserRef}
      alignItems="center"
      className="equipment-check-content-inner"
      style={{ height: '100%' }}
    >
      {!currentTest && !failureReason && (
        <>
          <Grid
            container
            item
            alignItems="center"
            justify="center"
            className={classes.headerContainer}
          >
            <h2 className={classes.headerStyle} style={{ margin: '0' }}>
              LET’S MAKE SURE THIS WILL BE A SMOOTH PROCESS.
            </h2>
          </Grid>
          <Grid item xs={12}>
            <p
              className={classes.pStyle}
              style={{ padding: '1rem', height: '33.33%' }}
            >
              We need to check your browser, device, and internet connection
              before we can begin. Once you are ready click the START TEST
              button below to run our device check.
            </p>
          </Grid>
          <Grid
            xs={12}
            container
            item
            justify="center"
            style={{ height: '33.33%' }}
            alignItems="center"
            className="1"
          >
            <ReusableButton
              type="button"
              variant="contained"
              className={classes.button}
              theme={theme}
              color="primary"
              onClick={(evt) => handleStartCheck(evt)}
              endIcon={<ArrowForwardIosIcon />}
              style={{ height: 'fit-content' }}
            >
              Start Test
            </ReusableButton>
          </Grid>
        </>
      )}
      {failureReason === 'device' && (
        <>
          <Grid
            container
            item
            alignItems="center"
            justify="center"
            className={classes.headerContainer}
          >
            <h2 className={classes.headerStyle} style={{ margin: '0' }}>
              WE HAVE A PROBLEM!
            </h2>
          </Grid>
          <Grid
            container
            item
            xs={12}
            justify="center"
            alignItems="center"
            style={{ height: '11%' }}
          >
            <p
              className={classes.pStyle}
              style={{ margin: '0', textAlign: 'center', padding: '0 8px' }}
            >
              It appears the device you are using is not compatible with our
              system. Please move to a computer device which is supported.
            </p>
          </Grid>
          <Grid
            item
            xs={12}
            container
            justify="center"
            alignItems="center"
            style={{ height: '11%' }}
          >
            <p
              className={classes.recommendedHeader}
              style={{ margin: '0', textAlign: 'center' }}
            >
              Recommended Device below:
            </p>
          </Grid>
          <Grid
            container
            item
            justify="space-evenly"
            xs={12}
            alignItems="center"
            style={{ height: '12%' }}
          >
            <Grid item>
              <p className={classes.pStyle}>Windows 7 & 10</p>
            </Grid>
            <Grid item>
              <p className={classes.pStyle}>macOs Catalina & Big Sur</p>
            </Grid>
          </Grid>
          <Grid
            xs={12}
            container
            item
            justify="center"
            style={{ height: '32%' }}
            alignItems="center"
            className="1"
          >
            <ReusableButton
              theme={theme}
              type="button"
              variant="contained"
              color="default"
              className={classes.button}
              onClick={(evt) => {
                setFailureReason(null);
                handleStartCheck(evt);
              }}
              endIcon={<RefreshIcon />}
            >
              Try Again
            </ReusableButton>
          </Grid>
        </>
      )}
      {failureReason === 'browser' && (
        <>
          <Grid
            container
            item
            alignItems="center"
            justify="center"
            className={classes.headerContainer}
          >
            <h2 className={classes.headerStyle} style={{ margin: '0' }}>
              WE HAVE A PROBLEM!
            </h2>
          </Grid>
          <Grid
            container
            item
            xs={12}
            justify="center"
            alignItems="center"
            style={{ height: '11%' }}
          >
            <p
              className={classes.pStyle}
              style={{ margin: '0', textAlign: 'center', padding: '0 8px' }}
            >
              It appears the browser you picked is not compatible <br />
              with our system.
            </p>
          </Grid>
          <Grid
            item
            xs={12}
            container
            justify="center"
            alignItems="center"
            style={{ height: '11%' }}
          >
            <p
              className={classes.recommendedHeader}
              style={{ margin: '0', textAlign: 'center' }}
            >
              Supported browsers below:
            </p>
          </Grid>
          <Grid
            container
            item
            justify="space-evenly"
            xs={12}
            alignItems="center"
            style={{ height: '12%' }}
          >
            {disableFirefoxCheck === false && (
              <Grid item>
                <a
                  style={{ textDecoration: 'none' }}
                  href="https://www.mozilla.org/en-US/firefox/new/"
                >
                  <p>Firefox {minCompatibleVersions.firefox} or newer</p>
                </a>
              </Grid>
            )}
            {disableChromeCheck === false && (
              <Grid item>
                <a
                  style={{ textDecoration: 'none' }}
                  href="https://www.google.com/chrome/"
                >
                  <p>Chrome {minCompatibleVersions.chrome} or newer</p>
                </a>
              </Grid>
            )}
            {disableSafariCheck === false && (
              <Grid item>
                <a
                  style={{ textDecoration: 'none' }}
                  href="https://support.apple.com/downloads/safari"
                >
                  <p>Safari {minCompatibleVersions.safari} or newer</p>
                </a>
              </Grid>
            )}
          </Grid>
          <Grid
            xs={12}
            container
            item
            justify="center"
            style={{ height: '32%' }}
            alignItems="center"
            className="1"
          >
            <ReusableButton
              theme={theme}
              type="button"
              variant="contained"
              color="default"
              className={classes.button}
              onClick={(evt) => {
                setFailureReason(null);
                handleStartCheck(evt);
              }}
              endIcon={<RefreshIcon />}
            >
              Try Again
            </ReusableButton>
          </Grid>
        </>
      )}
      {failureReason === 'network' && (
        <>
          <Grid
            container
            item
            alignItems="center"
            justify="center"
            className={classes.headerContainer}
          >
            <h2 className={classes.headerStyle} style={{ margin: '0' }}>
              WE HAVE A PROBLEM!
            </h2>
          </Grid>
          <Grid
            container
            item
            xs={12}
            justify="center"
            alignItems="center"
            style={{ height: '11%' }}
          >
            <p
              className={classes.pStyle}
              style={{ margin: '0', textAlign: 'center', padding: '0 8px' }}
            >
              It appears your internet connection speed could be problematic
              during your session - please try again.
            </p>
          </Grid>
          <Grid
            item
            xs={12}
            container
            justify="center"
            alignItems="center"
            style={{ height: '11%' }}
          >
            <p
              className={classes.recommendedHeader}
              style={{ margin: '0', textAlign: 'center' }}
            >
              Minimum speed below:
            </p>
          </Grid>
          <Grid
            container
            item
            justify="space-evenly"
            xs={12}
            alignItems="center"
            style={{ height: '12%' }}
          >
            <Grid item>
              <p className={classes.pStyle}>Download 4 mbps</p>
            </Grid>
          </Grid>
          <Grid
            xs={12}
            container
            item
            justify="center"
            style={{ height: '32%' }}
            alignItems="center"
            className="1"
          >
            <ReusableButton
              theme={theme}
              type="button"
              variant="contained"
              color="default"
              className={classes.button}
              onClick={(evt) => {
                setCurrentTest('device');
                setFailureReason(null);
                handleStartCheck(evt);
              }}
              endIcon={<RefreshIcon />}
            >
              Try Again
            </ReusableButton>
          </Grid>
        </>
      )}

      {currentTest && !failureReason && (
        <BrowserLoader
          currentTest={currentTest}
          failureReason={failureReason}
          componentHeaderText={componentHeaderText}
          theme={theme}
          customBackground={customBackground}
        />
      )}

      {/*  {!compatatible && BrowserCompatibility({ currentBrowser })} */}
    </Grid>
  );
};

BrowserStep.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onFail: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  flags: PropTypes.object.isRequired,
};

export default BrowserStep;
