import { useCallback } from 'react';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';

import _ from 'lodash';
import VerficationDefaultIcon from '../icons/verificationDefaultIcon';
import HandLoader from './handLoader';

const useStyles = makeStyles((theme) => ({
  '@keyframes tracking-in-expand': {
    '0%': {
      letterSpacing: '-0.5em',
      opacity: '0',
    },
    '40%': {
      opacity: '0.6',
    },
    '100%': {
      opacity: '1',
    },
  },
  paper: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 'auto',
    background: theme.background,
  },
  rowContainer: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 'auto',
  },
  headerStyle: {
    textAlign: 'center',
    color: theme.headerTextColor,
    marginTop: '0',
    fontSize: '40px',
    fontWeight: '400',
  },
  headerAnimation: {
    // animation: 'tracking-in-expand 2s cubic-bezier(0.215, 0.610, 0.355, 1.000) infinite both',
    // animationName: '$tracking-in-expand',
  },
  control: {
    padding: theme.spacing(2),
  },
  pStyle: {
    textAlign: 'center',
    fontSize: 'x-large',
    fontWeight: '300',
  },
  deviceName: {
    fontSize: 'larger',
    marginLeft: '10px',
  },
  icon: {
    animation: 'slide 2s forwards',
    animationDelay: '0s',
  },
  '@keyframes slide': {
    '100%': { left: 0 },
  },
}));

const steps = ['device', 'browser', 'network', 'complete'];

const iconSize = 72;

export default function BrowserLoader({
  currentTest,
  failureReason,
  componentHeaderText,
  theme,
  customBackground
}) {
  const classes = useStyles();

  const loading = useCallback(
    (targetStep) => {
      // index of the step we need to determine component for
      const targetIndex = _.findIndex(steps, (el) => targetStep.includes(el));

      // index of the current step
      const index = _.findIndex(steps, (el) => currentTest.includes(el));
      let displayName = targetStep.toUpperCase();

      if (targetStep === 'NETWORK') {
        displayName = 'INTERNET';
      }

      if (targetIndex === index) {
        return (
          <Grid
            container
            item
            xs={12}
            className={classes.rowContainer}
            style={{ height: '30%' }}
          >
            <Grid container item xs={6} style={{ height: '70px' }}>
              <Grid
                item
                xs={6}
                className={classes.paper}
                style={{
                  justifyContent: 'flex-start',
                  paddingLeft: '25px',
                  height: '68px',
                }}
              >
                <span className={classes.pStyle}>{displayName}</span>
              </Grid>
              <Grid
                item
                xs={6}
                className={classes.paper}
                style={{ height: '68px', borderBottom: '2px solid #97979761' }}
              >
                <HandLoader borderBottom={1} />
              </Grid>
            </Grid>
          </Grid>
        );
      }

      if (targetIndex < index) {
        return (
          <Grid
            container
            item
            xs={12}
            className={classes.rowContainer}
            style={{ height: '30%' }}
          >
            <Grid container item xs={6} style={{ height: '70px' }}>
              <Grid
                item
                xs={6}
                className={classes.paper}
                style={{
                  justifyContent: 'flex-start',
                  paddingLeft: '25px',
                  height: '68px',
                }}
              >
                <span className={classes.pStyle}>{displayName}</span>
              </Grid>
              <Grid
                item
                xs={6}
                className={classes.paper}
                style={{ height: '68px' }}
              >
                <VerficationDefaultIcon
                  length={iconSize}
                  visibility="visible"
                  theme={theme}
                  animation="shake-vertical"
                />
              </Grid>
            </Grid>
          </Grid>
        );
      }

      return (
        <Paper
          elevation={0}
          className={classes.paper}
          style={{ height: '30%', flexDirection: 'column', backgroundColor: customBackground }}
        />
      );
    },
    [currentTest, theme]
  );

  return (
    <Grid container item xs={12} justify="center" style={{ height: '100%' }}>
      <Grid
        container
        justify="space-between"
        wrap="nowrap"
        style={{
          height: '48%',
          padding: '1rem',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Grid item>
          {currentTest && !failureReason && (
            <>
              <Grid container item alignItems="center" justify="center">
                <h2
                  className={`${classes.headerStyle} ${componentHeaderText === 'EVERYTHING LOOKS GOOD HERE!'
                    ? classes.headerAnimation
                    : ''
                    }`}
                >
                  {componentHeaderText}
                </h2>
              </Grid>
              <Grid container item justify="center">
                {currentTest !== 'completed' && (
                  <p className={classes.pStyle}>
                    We are checking your browser, device, and internet to ensure
                    we have no issues.
                  </p>
                )}
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          height: '4%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Divider
          variant="middle"
          style={{ height: '2px', width: '96%', backgroundColor: '#97979761' }}
        />
      </Grid>
      <Grid
        container
        item
        xs={12}
        style={{ height: '48%', flexFlow: 'column', paddingBottom: '1rem' }}
      >
        {loading('device')}
        {loading('browser')}
        {loading('network')}
      </Grid>
    </Grid>
  );
}
// Joe chnages here
