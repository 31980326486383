/* eslint-disable import/no-unresolved */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class AudioVisualizer extends Component {
  constructor(props) {
    super(props);
    this.canvas = React.createRef();
  }

  componentDidMount() {
    const { clientHeight } = this.canvas.current.parentNode;

    this.canvas.current.height = clientHeight;
  }

  componentDidUpdate() {
    // The draw function needs to run every time "audioData" is updated
    this.drawHandler();
  }

  drawHandler = () => {
    const { audioData, theme } = this.props;
    const canvas = this.canvas.current;

    const WIDTH = canvas.width;
    const HEIGHT = canvas.height;

    const context = canvas.getContext('2d');
    const sliceWidth = (WIDTH * 1.0) / audioData.length;

    // Setting our drawing style
    context.lineWidth = 1;

    context.fillStyle = 'white';
    context.fillRect(0, 0, WIDTH, HEIGHT);

    context.strokeStyle = theme.iconColor;
    context.clearRect(0, 0, WIDTH, HEIGHT);

    // Begin the path we're going to draw and move the drawing position to
    // halfway down left side of the canvas
    context.beginPath();
    context.moveTo(0, HEIGHT / 2);

    // Each data point is between 0 and 255 and ...
    let x = 0;
    /* eslint-disable */
    for (const item of audioData) {
      // ... to normalize this to our canvas we divide by 255 and then multiply
      // by the height of the canvas
      const y = (item / 255.0) * HEIGHT;

      context.fillStyle = theme.iconColor;
      context.fillRect(x, HEIGHT - y, sliceWidth, y);

      // We then draw a line from the previous point to this one and
      // increment "x" by the "sliceWidth"
      context.lineTo(x, y);
      x += sliceWidth + 1;
    }
    /* eslint-enable */

    // We draw a line to the point halfway down the right of the canvas and
    // direct the canvas to color the entire path.
    context.lineTo(WIDTH, HEIGHT / 2);
    context.stroke();
  };

  render() {
    return (
      <canvas
        style={{
          height: '100px',
          width: '60%',
          borderWidth: 'thin',
          borderColor: '#006F83',
          background: this.props.theme.background,
        }}
        ref={this.canvas}
      />
    );
  }
}

AudioVisualizer.propTypes = {
  audioData: PropTypes.objectOf(PropTypes.number),
};

AudioVisualizer.defaultProps = {
  audioData: {},
};

export default AudioVisualizer;
