import { delay } from 'bluebird';
import toPromise from './Promisify';

/* To determine if a host is reachable from your network */
export const isHostReachable = async () => {
  // Issue request and handle the response
  // Handle IE and more capable browser
  const xhr = new (window.ActiveXObject || XMLHttpRequest)('Microsoft.XMLHTTP');

  const searchParams = `?rand=${Math.floor((1 + Math.random()) * 0x1e4)}`;

  // Open new request as a "HEAD" to the root hostname with a random param to bust the cache
  xhr.open('HEAD', `//${window.location.host}/${searchParams}`, true);

  return Promise.resolve(xhr).then(toPromise);
};

const bytesToMegabits = (bytes) => (bytes * 8) / 1024 / 1024;

export const getNetworkDownloadSpeedTest = async () => {
  const fileToFetch =
    'https://organization.enotarylog.com/static/img/enotary-instructional-video.mp4';

  const downloadSize = 7590291; // In bytes
  const minSpeedInMbps = 4;
  const maxSecondsToDownload = bytesToMegabits(downloadSize) / minSpeedInMbps;

  const result = await Promise.race([
    // Try to fetch the file. You need the .blob() part or else it won't necessarily read the whole file
    fetch(fileToFetch, { cache: 'reload' }).then((res) => res.blob()),
    // Set a timer for the maximum amount of time it can take
    delay(maxSecondsToDownload * 1000).then(() => 'ran out of time'),
  ]);

  if (result === 'ran out of time') {
    throw new Error('Network too slow');
  }
};
