/* eslint-disable @typescript-eslint/triple-slash-reference */
/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable @typescript-eslint/ban-types */
/// <reference path="../../../../../assets/v10/types.d.ts" />

import { Annotations, PDFNet } from '@pdftron/webviewer';
import React from 'react'
import _ from 'lodash';
import bPromise from 'bluebird';
import moment from 'moment';
import cheerio from 'cheerio';
import { EnlWebviewerInstance, IAnnotSpec } from '../types';
import addSpecialAnnots from '../lib/helpers/addSpecialAnnots';
import { mergeAnnotCommands  } from './mergeAnnotCommands';
import saveAs from 'save-as';

import debug from 'debug';
import { NotaryApi, useApi } from '@enotarylog/shared';
import { getGenerateXfdfFromAnnotComands } from './generateXfdfFromAnnotCommands';
import { removeFromXFDF } from './removeFromXfdf';

const log = debug('hooks:useWebviewerFn');
const license = process.env.NX_PDFTRON_LICENSE;
(window as any).cheerio = cheerio;
(window as any)._ = _;

export function useWebviewerFn(wv: EnlWebviewerInstance, token: string) {

  const api = useApi<NotaryApi>();
  const [instance, setInstance] = React.useState(wv);

  React.useEffect(() => {
    setInstance(wv);
  }, [wv])


  const sealPDF = React.useCallback(async (docTitle: string, userId: string, rawdoc: PDFNet.PDFDoc | ArrayBuffer, certFile: ArrayBuffer, certPassword: string, fullName: string, location: string, forceApi = false) => {
    const PDFNet = instance.PDFNet;

    const doc = ((rawdoc instanceof ArrayBuffer || rawdoc instanceof Uint8Array) ? await PDFNet.PDFDoc.createFromBuffer(rawdoc) : rawdoc) as PDFNet.PDFDoc;

    try {
      if (forceApi === true) {
        // eslint-disable-next-line no-throw-literal
        throw 'Failed to parse private key file';
      }


      await doc.flattenAnnotations(false);

      // lock the document before a write operation
      // runWithCleanup will auto unlock when complete
      doc.lock();


      // Add an StdSignatureHandler instance to PDFDoc, making sure to keep track of it using the ID returned.
      const sigHandlerId = await doc.addStdSignatureHandlerFromBuffer(certFile, certPassword);

      const sigField = await doc.fieldCreate('Signature1', PDFNet.Field.Type.e_signature);
      const page1 = await doc.getPage(1);
      const widgetAnnot = await PDFNet.WidgetAnnot.create((await doc.getSDFDoc()), (await PDFNet.Rect.init(0, 0, 0, 0)), sigField);
      page1.annotPushBack(widgetAnnot);
      widgetAnnot.setPage(page1);
      const widgetObj = await widgetAnnot.getSDFObj();
      widgetObj.putNumber('F', 132);
      widgetObj.putName('Type', 'Annot');


      const sigDict = await sigField.useSignatureHandler(sigHandlerId);
      const year = moment().utc().format('YYYY');
      const month = moment().utc().format('MM');
      const dd = moment().utc().format('DD');
      const hr = moment().utc().format('HH');
      const mins = moment().utc().format('mm');
      const secs = moment().utc().format('ss');

      // TODO: timezone offset is hardcoded to florida. figure out how to determine the offset of the server
      const date = new PDFNet.Date(parseInt(year), parseInt(month), parseInt(dd), parseInt(hr), parseInt(mins), parseInt(secs));
      const date_obj = await sigDict.putString('M', `D:${year}${month}${dd}${secs}${hr}${secs}-00'00'`);
      await date.update(date_obj);

      // sigDict.putName('SubFilter', 'adbe.pkcs7.detached');
      sigDict.putString('Name', fullName || 'eNotaryLog');
      sigDict.putString('Organization', 'eNotaryLog, LLC');
      sigDict.putString('Location', location || 'Tampa, Florida');
      sigDict.putString('Reason', 'Document verification');

      const root = await doc.getRoot();
      const perms = await root.putDict('Perms');
      perms.put('DocMDP', sigDict);

      const refObj = await sigDict.putArray('Reference');
      const transform = await refObj.pushBackDict();
      transform.putName('TransformMethod', 'DocMDP');
      transform.putName('Type', 'SigRef');
      const transformParams = await transform.putDict('TransformParams');
      transformParams.putNumber('P', 1); // Set permissions as necessary.
      transformParams.putName('Type', 'TransformParams');
      transformParams.putName('V', '1.2');


      return doc.saveMemoryBuffer(0);
    } catch (err) {
      if (_.isString(err) && err.includes('Failed to parse private key file')) {
        log('failed to seal pdf in the frontend. sending to api for sealing');
        const { url, gcsRefId } = await api.getUploadUrl(docTitle);
        const docBuffer = await doc.saveMemoryBuffer(0);
        const blob = new Blob([docBuffer], { type: 'application/pdf' });
        const file = new window.File([blob], docTitle, { type: blob.type });

        await api.uploadToGcs(url, file);
        const resp = await api.sealPdf(gcsRefId, userId, location);

        const arrayBuffer = await await fetch(resp.url).then(resp => resp.arrayBuffer());

        return new Uint8Array(arrayBuffer);
      }

      console.log(err);
      throw err;
    }
  }, [api, instance?.PDFNet]);

  // flatten pdf with annots
  const flattenPdf = React.useCallback(async (pdfDoc: PDFNet.PDFDoc, xfdf?: string) => {

    log('xfdfString', { xfdf })

    const PDFNet = instance.PDFNet;

    // const pdfDoc = await PDFNet.PDFDoc.createFromBuffer(buffer);

    if (xfdf) {
      const fdfDoc = await PDFNet.FDFDoc.createFromXFDF(xfdf);
      await pdfDoc.fdfMerge(fdfDoc);
    }

    await pdfDoc.flattenAnnotations(false);
    // return pdfDoc.saveMemoryBuffer(linearize ? PDFNet.SDFDoc.SaveOptions.e_linearized : 0);
    return pdfDoc;
  }, [instance]);


  const reorientBuffer = React.useCallback(async (pdfDoc: PDFNet.PDFDoc): Promise<PDFNet.PDFDoc> => {
    const PDFNet = instance.PDFNet;

    await pdfDoc.initSecurityHandler();

    const itr = await pdfDoc.getPageIterator();

    const importPages = [];
    let needReorient = false;

    for (itr; await itr.hasNext(); await itr.next()) {
      const page = await itr.current();

      importPages.push(page);
      const rotation = await page.getRotation();

      needReorient = needReorient || rotation > 0;
    }

    if (!needReorient) {
      console.info('document does not need to be reoriented');

      return pdfDoc;
    }

    const newDoc = await PDFNet.PDFDoc.create();

    await newDoc.initSecurityHandler();


    const builder = await PDFNet.ElementBuilder.create();
    const writer = await PDFNet.ElementWriter.create();

    const importedPages = await newDoc.importPages(importPages);


    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < importPages.length; ++i) {
      // Create a blank new A3 page and place on it two pages from the input document.
      const page = importedPages[i];
      const mediabox = await page.getMediaBox();

      const rotation = await page.getRotation();

      if (rotation === PDFNet.Page.Rotate.e_90 || rotation === PDFNet.Page.Rotate.e_270) {
        await mediabox.set(mediabox.x1, mediabox.y1, mediabox.y2, mediabox.x2);
      }

      const midPoint = await mediabox.width();
      const newPage = await newDoc.pageCreate(mediabox);

      writer.beginOnPage(newPage);

      // Place the first page
      const srcPage = importedPages[i];
      const element = await builder.createFormFromPage(srcPage);
      const scX = midPoint / await srcPage.getPageWidth();
      const scY = await mediabox.height() / await srcPage.getPageHeight();

      const scale = scX < scY ? scX : scY; // min(sc_x, sc_y)

      await element.getGState().then((gstate) => gstate.setTransform(scale, 0, 0, scale, 0, 0));
      await writer.writePlacedElement(element);


      await writer.end();
      newDoc.pagePushBack(newPage);
    }


    // await newDoc.save('../../TestFiles/Output/newsletter_merged.pdf', PDFNet.SDFDoc.SaveOptions.e_linearized);
    return newDoc;
    // const pdfData = await newDoc.saveMemoryBuffer(PDFNet.SDFDoc.SaveOptions.e_linearized);

    // return pdfData;
  }, [instance])


  const processBlankPages = React.useCallback(async (pdfDoc: PDFNet.PDFDoc, numBlankPages) => {
    if (numBlankPages > 0) {
      await bPromise.mapSeries(Array.from(Array(numBlankPages)), async () => {
        const x = await pdfDoc.pageCreate();

        await pdfDoc.pagePushBack(x);
      });
    }

    return pdfDoc;
  }, []);


  const padFreeText = (xfdf) => {
    if (_.isEmpty(xfdf)) {
      return xfdf;
    }
    const $ = cheerio.load(xfdf, { xmlMode: true });

    $('annots').children('freetext').map((i, ft) => {
      const rect = $(ft).attr('rect');
      const [x1, y1, x2, y2] = _.split(rect, ',');
      const newRect = [x1, parseFloat(y1) - 20, parseFloat(x2) + 20, y2].join(',');

      return $(ft).attr('rect', newRect);
    });

    return $.xml();
  };

  type AnnotSpec = Record<string, IAnnotSpec>;
  type DocSpec = Record<string, { url: string, title: string, file?: Blob, originalUrl?: string, gcsRefId: string }>



  const generateXfdf = React.useCallback(
    async (
      docId: string,
      docs: DocSpec,
      xfdfs: Record<string, string>,
      annots: AnnotSpec | null,
      blankPages: number = 0
    ) => {
      const selectedDocId = instance.getDocId();
      const isCurrentlySelectedDoc = docId === selectedDocId;

      if (!isCurrentlySelectedDoc) {
        const docURL = docs[docId]?.originalUrl || docs[docId]?.url;

        await new Promise((res, rej) => {
          instance.docViewer.one('documentLoaded', () => {
            instance.setDocId(docId);
            res(instance.docViewer.getDocument());
          })
          instance.loadDocument(docURL, {
            filename: docs[docId]?.title,
            documentId: docId,
            extension: 'pdf',
            withCredentials: false,
            customHeaders: !_.includes(docURL, window.location.host)
              ? {}
              : { Authorization: `Bearer ${token}` },
          });


        })
      }

      // Process blank pages.
      const pageCount = instance.docViewer.getPageCount();

      const doc = instance.docViewer.getDocument();

      if (blankPages > 0 && !isCurrentlySelectedDoc) {
        const { width, height } = doc.getPageInfo(pageCount - 1);

        await doc.insertBlankPages(
          _.range(pageCount + 1, pageCount + (blankPages || 0) + 1),
          width,
          height
        );
      }

      // Exclude annotation if it's a template, it's hidden, or it's on a page that doesn't exist.
      const shouldExclude = (annot: Annotations.Annotation) =>
        _.toLower(annot.CustomData.type).includes('template') ||
        _.toLower(annot.Subject).includes('template') ||
        annot.Hidden === true ||
        annot.PageNumber > pageCount + (blankPages || 0);

      // sort by createdAt so that annotation order is preserved
      // `annots` is `null` if the document didn't have any annotations in Firebase.
      const firebaseAnnots = annots
        ? (_.chain(annots)
            .values()
            .sortBy('createdAt')
            .value() as unknown as IAnnotSpec[])
        : [];

      // Import firebase annotations to docviewer.
      let importedAnnots: Annotations.Annotation[] = [];
      let xfdf = await getGenerateXfdfFromAnnotComands(instance)(firebaseAnnots);

      importedAnnots = await instance.annotManager.importAnnotations(xfdf);


      const freeTexts = instance.annotManager.getAnnotationsList().filter(a => a instanceof instance.Annotations.FreeTextAnnotation && !a.Hidden && !_.includes(_.toLower(a.Subject), 'template') && !_.includes(_.toLower(a.CustomData.type), 'template'));

      // find all freetexts where it has no richtext style other than color
      // don't count color: '#00000' as a rich text style
      const hasRichTextStyle = _.filter(freeTexts, (ft) => {
        const richTextStyles = ft.getRichTextStyle();
        const styles = _.chain(richTextStyles)
          .toPairs()
          .filter(([k, v]) => {
            return _.chain(v)
              .toPairs()
              .filter(([kk, vv]) => kk !== 'color' || (kk === 'color' && vv !== '#000000'))
              .fromPairs()
              .thru((f) => !_.isEmpty(f))
              .value();
          })
          .fromPairs()
          .value();

        return !_.isEmpty(styles) && ft && ft.Hidden !== true && !_.includes(_.toLower(ft.getCustomData('type')), 'template') && !_.includes(_.toLower(ft.Subject), 'template')
      });



      // Filter out annotations that should be excluded.
      const [annotsToRemove, annotList] = _.partition(importedAnnots, shouldExclude);
      xfdf = removeFromXFDF(xfdf, _.map(_.filter(importedAnnots, shouldExclude), (el) => el.Id));

      // Remove annotations that should be excluded.
      instance.annotManager.deleteAnnotations(annotsToRemove, true, true);


      // Export annotations to XFDF.
      xfdf = await instance.annotManager.exportAnnotations({
        annotList: _.differenceBy(annotList, hasRichTextStyle, 'Id'),
        widgets: false,
        fields: false,
      });


      const $ = cheerio.load(xfdf, { xmlMode: true });


      const updatedXfdf = $.xml();

      // Add special annotations to XFDF (white out and strike out).
      const finalXfdf = await addSpecialAnnots(updatedXfdf, xfdfs[docId]);

      let fileData = await doc.getFileData({
        flatten: false,
        xfdfString: padFreeText(finalXfdf),
      })

      if (hasRichTextStyle.length > 0) {
        const license = 'eNotaryLog, LLC (enotarylog.com):OEM:eNotaryLog::B+:AMS(20240330):20A5BABD0477A80A0360B13AC982537860612F83FF480E2B9D8586734C8F4E902A4935F5C7';
        Core.setWorkerPath('/v10/core')
        await Core.PDFNet.initialize(license)

        const pdfDoc = await Core.PDFNet.PDFDoc.createFromBuffer(fileData);
        const fdfDoc = await Core.PDFNet.FDFDoc.createFromXFDF(await instance.annotManager.exportAnnotations({
          annotList: hasRichTextStyle
        }));

        await pdfDoc.fdfMerge(fdfDoc);
        await pdfDoc.flattenAnnotations(false);
        fileData = await pdfDoc.saveMemoryBuffer(0);

      }

      const arrayBuffer = new Uint8Array(fileData);

      return {
        xfdfString: padFreeText(finalXfdf),
        arrayBuffer,
      };
    },
    [
      instance?.CoreControls?.AnnotationManager,
      instance?.CoreControls?.DocumentViewer,
      instance?.annotManager,
      token,
    ]
  );


  return {
    generateXfdf,
    padFreeText,
    sealPDF,
    flattenPdf,
    reorientBuffer,
    processBlankPages,
  };
}

export default useWebviewerFn;
