import { makeStyles } from '@material-ui/core';
import tabDefaultSvg from './tabDefaultSvg';

const useStyles = makeStyles(() => ({
  completedStyle: {
    filter: 'grayscale(80%)',
  },
}));

export default function TabSvg({ step, theme, completed }) {
  const styles = useStyles();

  const customIcon = theme[`${step.toLowerCase()}Icon`];

  if (customIcon) {
    if (completed) {
      return (
        <img
          alt={`${step} Icon`}
          className={styles.completedStyle}
          src={customIcon}
          style={{ width: '40px', height: '40px' }}
        />
      );
    }

    return (
      <img
        alt={`${step} Icon`}
        src={customIcon}
        style={{ width: '40px', height: '40px' }}
      />
    );
  }

  const Svg = tabDefaultSvg[step.toLowerCase()][completed ? 'off' : 'on'];

  return <Svg />;
}
