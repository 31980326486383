/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-tabs */
/* eslint-disable max-len */
import { makeStyles } from '@material-ui/core';
import Color from 'color';

const useStyle = makeStyles((theme) => {
  const color = Color(theme.iconColor);
  const lightness = color.lightness();
  const iconColorLuminosity = color.luminosity();

  let brighterColor;
  let darkerColor;

  if (iconColorLuminosity <= 0.5) {
    brighterColor = color.lightness(lightness + 25).hex();
    darkerColor = color.lightness(lightness - 15).hex();
  } else {
    darkerColor = color.lightness(lightness - 30).hex();
    brighterColor = color.lightness(lightness + 30).hex();
  }

  const fill = color.isLight() ? darkerColor : brighterColor;

  return {
    iconColor: {
      fill: theme.iconColor,
    },
    iconHighlightColor: {
      fill,
    },
  };
});

export default {
  device: {
    on: () => {
      const classes = useStyle();

      return (
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          width="40px"
          height="40px"
          viewBox="0 0 512 512"
          style={{ enableBackground: 'new 0 0 512 512' }}
          xmlSpace="preserve"
        >
          <style type="text/css">
            .device-st0{'{'}fill: #3F4A4A{'}'}
            .device-st1{'{'}fill:#E1EBF0;{'}'}
            .device-st2{'{'}fill:#323C3C;{'}'}
            .device-st3{'{'}fill:#CDE1E6;{'}'}
          </style>
          <g>
            <path
              id="XMLID_1995_"
              className="device-st0"
              d="M294,341.6h-76c-8.8,0.6-15.5,8.9-15,18.5v71.1c-0.5,9.6,6.2,17.8,15,18.5h76
       c8.8-0.6,15.5-8.9,15-18.5v-71.1C309.5,350.5,302.8,342.3,294,341.6z"
            />
            <g id="XMLID_2000_" transform="translate(434.392 574.668)">
              <path
                id="XMLID_2001_"
                className={classes.iconHighlightColor}
                d="M-419.3-556.1H62.6v341.6h-481.9V-556.1z"
              />
            </g>
            <g id="XMLID_88_" transform="translate(448.572 592.153)">
              <path
                id="XMLID_2003_"
                className="device-st2"
                d="M-154.6-250.5h-38v108.2h38c8.8-0.6,15.5-8.9,15-18.5v-71.1
         C-139-241.5-145.7-249.9-154.6-250.5z"
              />
            </g>
            <g id="Group_22" transform="translate(448.572 574.668)">
              <path
                id="XMLID_2007_"
                className={classes.iconColor}
                d="M-192.6-556.1H48.4v341.6h-240.9V-556.1z"
              />
            </g>
            <g id="XMLID_1996_" transform="translate(442.18 596.199)">
              <path
                id="XMLID_1997_"
                className="device-st0"
                d="M-92.6-142.7h-187.1c-9.4-1.3-16.1-10.7-14.9-20.9c1-8.5,7.1-15.1,14.9-16.2h187.1
         c9.4,1.3,16.1,10.7,14.9,20.9C-78.7-150.4-84.8-143.8-92.6-142.7L-92.6-142.7z"
              />
            </g>
            <path
              id="XMLID_1998_"
              className="device-st0"
              d="M496.9,378.7H15.1c-8.8-0.6-15.5-8.9-15-18.5V18.6C-0.5,9,6.2,0.7,15.1,0.1h481.9
       c8.8,0.6,15.5,8.9,15,18.5v341.6C512.5,369.8,505.8,378.1,496.9,378.7z M30.1,341.6h451.8V37.1H30.1V341.6z"
            />
            <g id="XMLID_75_" transform="translate(448.572 596.199)">
              <path
                id="XMLID_2006_"
                className="device-st2"
                d="M-99-179.8h-93.6v37.1H-99c9.4-1.3,16.1-10.7,14.9-20.9C-85.1-172.1-91.2-178.7-99-179.8z"
              />
            </g>
            <path
              id="XMLID_2008_"
              className="device-st2"
              d="M496.9,0H256v37.1h226v304.4H256v37.1h240.9c8.8-0.6,15.5-8.9,15-18.5V18.6
       C512.5,8.9,505.8,0.6,496.9,0z"
            />
            <g id="tablet" transform="translate(436.404 587.098)">
              <path
                id="Path_78"
                className="device-st2"
                d="M-245.2-338.9h-123.5c-11,0.9-19.3,11.2-18.5,23.2v217.4c-0.7,12,7.5,22.3,18.5,23.2h123.5
         c11-0.9,19.3-11.2,18.5-23.2v-217.4C-225.9-327.6-234.2-338-245.2-338.9z"
              />
              <path
                id="Path_79"
                className="device-st2"
                d="M-226.6-315.7v217.4c0.7,12-7.5,22.3-18.5,23.2h-61.7v-263.8h61.7
         C-234.2-338-225.9-327.6-226.6-315.7z"
              />
              <path
                id="Path_80"
                className="device-st1"
                d="M-248.9-315h-113.8c-3.4,0.3-5.9,3.4-5.7,7.1c0,0,0,0,0,0.1v201.9c-0.3,3.7,2.3,6.9,5.6,7.2
         c0,0,0,0,0,0h113.8c3.4-0.3,5.9-3.4,5.7-7.1c0,0,0,0,0-0.1v-201.9C-242.9-311.5-245.5-314.7-248.9-315
         C-248.8-315-248.9-315-248.9-315z"
              />
              <path
                id="Path_81"
                className="device-st3"
                d="M-243.2-307.9v201.9c0.2,3.7-2.3,6.9-5.7,7.2c0,0-0.1,0-0.1,0h-57.9V-315h57.9
         C-245.6-314.8-243-311.6-243.2-307.9C-243.2-307.9-243.2-307.9-243.2-307.9z"
              />
              <path
                id="Path_82"
                className="device-st0"
                d="M-325.4-121.6h37v15.4h-37V-121.6z"
              />
              <path
                id="Path_83"
                className="device-st2"
                d="M-306.9-121.6h18.5v15.4h-18.5V-121.6z"
              />
            </g>
            <g id="tablet-2" transform="translate(456.404 590.176)">
              <path
                id="Path_78-2"
                className="device-st2"
                d="M10.6-285.1h-67.7c-6.4,1-11,7.3-10.2,14.3v134.4c-0.8,7,3.8,13.4,10.2,14.3h67.8
         c6.4-1,11-7.3,10.2-14.3v-134.4C21.6-277.7,17-284.1,10.6-285.1z"
              />
              <path
                id="Path_79-2"
                className="device-st2"
                d="M20.8-270.7v134.4c0.8,7-3.8,13.4-10.2,14.3h-33.8v-163.1h33.9
         C17-284.1,21.5-277.7,20.8-270.7z"
              />
              <path
                id="Path_80-2"
                className="device-st1"
                d="M8.4-272.9H-55c-1.7,0-3.2,2-3.2,4.6v129.3c0,2.6,1.4,4.6,3.2,4.6H8.4c1.7,0,3.2-2,3.2-4.6
         v-129.3C11.5-270.9,10.2-272.9,8.4-272.9z"
              />
              <path
                id="Path_81-2"
                className="device-st3"
                d="M11.6-268.3v129.3c0,2.6-1.4,4.6-3.2,4.6h-31.7v-138.6H8.4C10.2-272.9,11.6-270.9,11.6-268.3z
         "
              />
              <path
                id="Path_82-2"
                className="device-st0"
                d="M-33.5-150.7h20.4v9.6h-20.4V-150.7z"
              />
              <path
                id="Path_83-2"
                className="device-st2"
                d="M-23.3-150.7h10.2v9.6h-10.2V-150.7z"
              />
            </g>
          </g>
        </svg>
      );
    },
    off: () => {
      const classes = useStyle();

      return (
        <svg
          version="1.1"
          id="Layer_1"
          width="40px"
          height="40px"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 512 512"
          style={{ enableBackground: 'new 0 0 512 512' }}
          xmlSpace="preserve"
        >
          <style type="text/css">
            .device-st0{'{'}fill:#3F4A4A;{'}'}
            .device-st1{'{'}fill:#E1EBF0;{'}'}
            .device-st2{'{'}fill:#323C3C;{'}'}
            .device-st3{'{'}fill:#CDE1E6;{'}'}
          </style>
          <g>
            <path
              id="XMLID_1995_"
              className="device-st0"
              d="M294,341.6h-76c-8.8,0.6-15.5,8.9-15,18.5v71.1c-0.5,9.6,6.2,17.8,15,18.5h76
		c8.8-0.6,15.5-8.9,15-18.5v-71.1C309.5,350.5,302.8,342.3,294,341.6z"
            />
            <g id="XMLID_2000_" transform="translate(434.392 574.668)">
              <path
                id="XMLID_2001_"
                className={classes.iconHighlightColor}
                d="M-419.3-556.1H62.6v341.6h-481.9V-556.1z"
              />
            </g>
            <g id="XMLID_88_" transform="translate(448.572 592.153)">
              <path
                id="XMLID_2003_"
                className="device-st2"
                d="M-154.6-250.5h-38v108.2h38c8.8-0.6,15.5-8.9,15-18.5v-71.1
			C-139-241.5-145.7-249.9-154.6-250.5z"
              />
            </g>
            <g id="Group_22" transform="translate(448.572 574.668)">
              <path
                id="XMLID_2007_"
                className={classes.iconColor}
                d="M-192.6-556.1H48.4v341.6h-240.9V-556.1z"
              />
            </g>
            <g id="XMLID_1996_" transform="translate(442.18 596.199)">
              <path
                id="XMLID_1997_"
                className="device-st0"
                d="M-92.6-142.7h-187.1c-9.4-1.3-16.1-10.7-14.9-20.9c1-8.5,7.1-15.1,14.9-16.2h187.1
			c9.4,1.3,16.1,10.7,14.9,20.9C-78.7-150.4-84.8-143.8-92.6-142.7L-92.6-142.7z"
              />
            </g>
            <path
              id="XMLID_1998_"
              className="device-st0"
              d="M496.9,378.7H15.1c-8.8-0.6-15.5-8.9-15-18.5V18.6C-0.5,9,6.2,0.7,15.1,0.1h481.9
		c8.8,0.6,15.5,8.9,15,18.5v341.6C512.5,369.8,505.8,378.1,496.9,378.7z M30.1,341.6h451.8V37.1H30.1V341.6z"
            />
            <g id="XMLID_75_" transform="translate(448.572 596.199)">
              <path
                id="XMLID_2006_"
                className="device-st2"
                d="M-99-179.8h-93.6v37.1H-99c9.4-1.3,16.1-10.7,14.9-20.9C-85.1-172.1-91.2-178.7-99-179.8z"
              />
            </g>
            <path
              id="XMLID_2008_"
              className="device-st2"
              d="M496.9,0H256v37.1h226v304.4H256v37.1h240.9c8.8-0.6,15.5-8.9,15-18.5V18.6
		C512.5,8.9,505.8,0.6,496.9,0z"
            />
            <g id="tablet" transform="translate(436.404 587.098)">
              <path
                id="Path_78"
                className="device-st0"
                d="M-245.2-338.9h-123.5c-11,0.9-19.3,11.2-18.5,23.2v217.4c-0.7,12,7.5,22.3,18.5,23.2h123.5
			c11-0.9,19.3-11.2,18.5-23.2v-217.4C-225.9-327.6-234.2-338-245.2-338.9z"
              />
              <path
                id="Path_79"
                className="device-st2"
                d="M-226.6-315.7v217.4c0.7,12-7.5,22.3-18.5,23.2h-61.7v-263.8h61.7
			C-234.2-338-225.9-327.6-226.6-315.7z"
              />
              <path
                id="Path_80"
                className="device-st1"
                d="M-248.9-315h-113.8c-3.4,0.3-5.9,3.4-5.7,7.1c0,0,0,0,0,0.1v201.9c-0.3,3.7,2.3,6.9,5.6,7.2
			c0,0,0,0,0,0h113.8c3.4-0.3,5.9-3.4,5.7-7.1c0,0,0,0,0-0.1v-201.9C-242.9-311.5-245.5-314.7-248.9-315
			C-248.8-315-248.9-315-248.9-315z"
              />
              <path
                id="Path_81"
                className="device-st3"
                d="M-243.2-307.9v201.9c0.2,3.7-2.3,6.9-5.7,7.2c0,0-0.1,0-0.1,0h-57.9V-315h57.9
			C-245.6-314.8-243-311.6-243.2-307.9C-243.2-307.9-243.2-307.9-243.2-307.9z"
              />
              <path
                id="Path_82"
                className="device-st0"
                d="M-325.4-121.6h37v15.4h-37V-121.6z"
              />
              <path
                id="Path_83"
                className="device-st2"
                d="M-306.9-121.6h18.5v15.4h-18.5V-121.6z"
              />
            </g>
            <g id="tablet-2" transform="translate(456.404 590.176)">
              <path
                id="Path_78-2"
                className="device-st0"
                d="M10.6-285.1h-67.7c-6.4,1-11,7.3-10.2,14.3v134.4c-0.8,7,3.8,13.4,10.2,14.3h67.8
			c6.4-1,11-7.3,10.2-14.3v-134.4C21.6-277.7,17-284.1,10.6-285.1z"
              />
              <path
                id="Path_79-2"
                className="device-st2"
                d="M20.8-270.7v134.4c0.8,7-3.8,13.4-10.2,14.3h-33.8v-163.1h33.9
			C17-284.1,21.5-277.7,20.8-270.7z"
              />
              <path
                id="Path_80-2"
                className="device-st1"
                d="M8.4-272.9H-55c-1.7,0-3.2,2-3.2,4.6v129.3c0,2.6,1.4,4.6,3.2,4.6H8.4c1.7,0,3.2-2,3.2-4.6
			v-129.3C11.5-270.9,10.2-272.9,8.4-272.9z"
              />
              <path
                id="Path_81-2"
                className="device-st3"
                d="M11.6-268.3v129.3c0,2.6-1.4,4.6-3.2,4.6h-31.7v-138.6H8.4C10.2-272.9,11.6-270.9,11.6-268.3z
			"
              />
              <path
                id="Path_82-2"
                className="device-st0"
                d="M-33.5-150.7h20.4v9.6h-20.4V-150.7z"
              />
              <path
                id="Path_83-2"
                className="device-st2"
                d="M-23.3-150.7h10.2v9.6h-10.2V-150.7z"
              />
            </g>
          </g>
        </svg>
      );
    },
  },
  microphone: {
    on: () => {
      const classes = useStyle();

      return (
        <svg
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 512 512"
          width="40px"
          height="40px"
          style={{ enableBackground: 'new 0 0 512 512' }}
          xmlSpace="preserve"
        >
          <style type="text/css">
            .microphone-st0{'{'}fill:#F3F5FB;{'}'}
            .microphone-st1{'{'}fill:#CED3DF;{'}'}
            .microphone-st2{'{'}fill: #3F4A4A{'}'}
            .microphone-st3{'{'}fill:#323C3C;{'}'}s
          </style>
          <path
            className="microphone-st0"
            d="M256,318.6c-55.5,0-100.6-45.1-100.6-100.6V100.6C155.4,45.1,200.5,0,256,0s100.6,45.1,100.6,100.6V218
	C356.6,273.5,311.5,318.6,256,318.6z"
          />
          <path
            className="st1"
            d="M256,0v318.6c55.5,0,100.6-45.1,100.6-100.6V100.6C356.6,45.1,311.5,0,256,0z"
          />
          <path
            className={classes.iconColor}
            d="M290.4,205.6c-8.3,0-15,6.7-15,15s6.7,15,15,15h64.7c1-5.7,1.5-11.6,1.5-17.6v-12.4H290.4z"
          />
          <path
            className={classes.iconColor}
            d="M356.6,145.6h-66.2c-8.3,0-15,6.7-15,15s6.7,15,15,15h66.2V145.6z"
          />
          <path
            className={classes.iconColor}
            d="M275.4,100.6c0,8.3,6.7,15,15,15h66.2v-15c0-5.1-0.4-10.1-1.1-15h-65.1C282.1,85.6,275.4,92.3,275.4,100.6z"
          />
          <g>
            <path
              className={classes.iconHighlightColor}
              d="M220.6,205.6h-65.2V218c0,6,0.5,11.9,1.5,17.6h63.7c8.3,0,15-6.7,15-15S228.9,205.6,220.6,205.6z"
            />
            <path
              className={classes.iconHighlightColor}
              d="M220.6,145.6h-65.2v30h65.2c8.3,0,15-6.7,15-15S228.9,145.6,220.6,145.6z"
            />
            <path
              className={classes.iconHighlightColor}
              d="M220.6,85.6h-64.1c-0.7,4.9-1.1,9.9-1.1,15v15h65.2c8.3,0,15-6.7,15-15S228.9,85.6,220.6,85.6L220.6,85.6z"
            />
          </g>
          <path
            className="microphone-st2"
            d="M401.6,165.6c-8.3,0-15,6.7-15,15V218c0,72-58.6,130.6-130.6,130.6S125.4,290.1,125.4,218v-37.4
	c0-8.3-6.7-15-15-15s-15,6.7-15,15V218c0,83.5,64.1,152.3,145.6,159.9v87.4c0,8.3,6.7,15,15,15s15-6.7,15-15v-87.4
	c81.6-7.6,145.6-76.4,145.6-159.9v-37.4C416.6,172.3,409.9,165.6,401.6,165.6z"
          />
          <path
            className="microphone-st3"
            d="M401.6,165.6c-8.3,0-15,6.7-15,15V218c0,72-58.6,130.6-130.6,130.6v131.7c8.3,0,15-6.7,15-15v-87.4
	c81.6-7.6,145.6-76.4,145.6-159.9v-37.4C416.6,172.3,409.9,165.6,401.6,165.6z"
          />
          <path
            className="microphone-st2"
            d="M328,462.3H184c-27.3,0-49.7,22.4-49.7,49.7h243.4C377.7,484.7,355.3,462.3,328,462.3z"
          />
          <path
            className="microphone-st3"
            d="M256,512h121.7c0-27.3-22.4-49.7-49.7-49.7h-72L256,512z"
          />
        </svg>
      );
    },
    off: () => {
      const classes = useStyle();

      return (
        <svg
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 512 512"
          width="40px"
          height="40px"
          style={{ enableBackground: 'new 0 0 512 512' }}
          xmlSpace="preserve"
        >
          <style type="text/css">
            .microphone-st0{'{'}fill:#F3F5FB;{'}'}
            .microphone-st1{'{'}fill:#CED3DF;{'}'}
            .microphone-st2{'{'}fill: #3F4A4A{'}'}
            .microphone-st3{'{'}fill:#323C3C;{'}'}
          </style>
          <path
            className="microphone-st0"
            d="M256,318.6c-55.5,0-100.6-45.1-100.6-100.6V100.6C155.4,45.1,200.5,0,256,0s100.6,45.1,100.6,100.6V218
	C356.6,273.5,311.5,318.6,256,318.6z"
          />
          <path
            className="microphone-st1"
            d="M256,0v318.6c55.5,0,100.6-45.1,100.6-100.6V100.6C356.6,45.1,311.5,0,256,0z"
          />
          <path
            className={classes.iconColor}
            d="M290.4,205.6c-8.3,0-15,6.7-15,15s6.7,15,15,15h64.7c1-5.7,1.5-11.6,1.5-17.6v-12.4H290.4z"
          />
          <path
            className={classes.iconColor}
            d="M356.6,145.6h-66.2c-8.3,0-15,6.7-15,15s6.7,15,15,15h66.2V145.6z"
          />
          <path
            className={classes.iconColor}
            d="M275.4,100.6c0,8.3,6.7,15,15,15h66.2v-15c0-5.1-0.4-10.1-1.1-15h-65.1C282.1,85.6,275.4,92.3,275.4,100.6z"
          />
          <g>
            <path
              className={classes.iconHighlightColor}
              d="M220.6,205.6h-65.2V218c0,6,0.5,11.9,1.5,17.6h63.7c8.3,0,15-6.7,15-15S228.9,205.6,220.6,205.6z"
            />
            <path
              className={classes.iconHighlightColor}
              d="M220.6,145.6h-65.2v30h65.2c8.3,0,15-6.7,15-15S228.9,145.6,220.6,145.6z"
            />
            <path
              className={classes.iconHighlightColor}
              d="M220.6,85.6h-64.1c-0.7,4.9-1.1,9.9-1.1,15v15h65.2c8.3,0,15-6.7,15-15S228.9,85.6,220.6,85.6L220.6,85.6z"
            />
          </g>
          <path
            className="microphone-st2"
            d="M401.6,165.6c-8.3,0-15,6.7-15,15V218c0,72-58.6,130.6-130.6,130.6S125.4,290.1,125.4,218v-37.4
	c0-8.3-6.7-15-15-15s-15,6.7-15,15V218c0,83.5,64.1,152.3,145.6,159.9v87.4c0,8.3,6.7,15,15,15s15-6.7,15-15v-87.4
	c81.6-7.6,145.6-76.4,145.6-159.9v-37.4C416.6,172.3,409.9,165.6,401.6,165.6z"
          />
          <path
            className="microphone-st3"
            d="M401.6,165.6c-8.3,0-15,6.7-15,15V218c0,72-58.6,130.6-130.6,130.6v131.7c8.3,0,15-6.7,15-15v-87.4
	c81.6-7.6,145.6-76.4,145.6-159.9v-37.4C416.6,172.3,409.9,165.6,401.6,165.6z"
          />
          <path
            className="microphone-st2"
            d="M328,462.3H184c-27.3,0-49.7,22.4-49.7,49.7h243.4C377.7,484.7,355.3,462.3,328,462.3z"
          />
          <path
            className="microphone-st3"
            d="M256,512h121.7c0-27.3-22.4-49.7-49.7-49.7h-72L256,512z"
          />
        </svg>
      );
    },
  },
  webcam: {
    on: () => {
      const classes = useStyle();

      return (
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          width="40px"
          height="40px"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 512 512"
          style={{ enableBackground: 'new 0 0 512 512' }}
          xmlSpace="preserve"
        >
          <style type="text/css">
            .webcam-st0{'{'}fill:#3F4A4A;{'}'}
            .webcam-st1{'{'}fill:#323C3C;{'}'}
            .webcam-st2{'{'}fill:#E1EBF0;{'}'}
            .webcam-st3{'{'}fill:#CDE1E6;{'}'}
            .webcam-st5{'{'}opacity:0.51;fill:#004FAC;enable-background:new ;
            {'}'}
          </style>
          <g id="Group_71" transform="translate(-438.119 -490.32)">
            <g id="web-camera_1_" transform="translate(438.119 490.32)">
              <path
                id="Path_52"
                className="webcam-st0"
                d="M415,486.6l-30.2-23.8c-30.8-23-48.9-57.1-49.6-93.4c-1-9-9.8-15.6-19.7-14.9H197.3
			c-9.9-0.7-18.7,5.9-19.7,14.9c-0.6,36.2-18.8,70.4-49.6,93.4l-30.2,23.8c-5.4,3.9-7.1,10.7-4,16.3c3.7,5.9,10.7,9.4,18.1,9.1
			h288.8c7.4,0.2,14.4-3.3,18.1-9.1C422,497.3,420.4,490.5,415,486.6z"
              />
              <path
                id="Path_53"
                className="webcam-st1"
                d="M384.7,462.7c-30.8-23-48.9-57.1-49.6-93.4c-1-9-9.8-15.6-19.7-14.9h-59.1v157.6h144.4
			c7.4,0.2,14.4-3.3,18.1-9.1c3.1-5.6,1.4-12.4-4-16.3L384.7,462.7z"
              />
              <path
                id="Path_54"
                className="webcam-st2"
                d="M256.4,390c-141.2,0-256-87.5-256-195s114.8-195,256-195s256,87.5,256,195S397.6,390,256.4,390z
			"
              />
              <path
                id="Path_55"
                className="webcam-st3"
                d="M256.4,0.1V390c141.2,0,256-87.5,256-195S397.6,0.1,256.4,0.1z"
              />
              <path
                id="Path_56"
                className="webcam-st0"
                d="M256.4,300c-76,0-137.9-47.1-137.9-105s61.8-105,137.9-105s137.9,47.1,137.9,105
			S332.4,300,256.4,300z"
              />
              <path
                id="Path_57"
                className="webcam-st1"
                d="M394.2,195c0-57.9-61.8-105-137.9-105v210C332.4,300,394.2,252.9,394.2,195z"
              />
              <path
                id="Path_58"
                d="M276.1,436.9c2,9.9-5.2,19.4-16,21.2c-10.9,1.8-21.3-4.7-23.3-14.6c-2-9.9,5.2-19.4,16-21.2
			c1.2-0.2,2.4-0.3,3.6-0.3C266.3,421.3,275.1,427.9,276.1,436.9z"
              />
              <path
                id="Path_60"
                className={classes.iconHighlightColor}
                d="M256.4,120.1c-54.3,0-98.5,33.6-98.5,75s44.2,75,98.5,75s98.5-33.6,98.5-75
			S310.7,120.1,256.4,120.1z"
              />
              <path
                id="Path_61"
                className={classes.iconColor}
                d="M354.9,195c0-41.3-44.2-75-98.5-75v150C310.7,270,354.9,236.4,354.9,195z"
              />
            </g>
          </g>
        </svg>
      );
    },
    off: () => {
      const classes = useStyle();

      return (
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          width="40px"
          height="40px"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 512 512"
          style={{ enableBackground: 'new 0 0 512 512' }}
          xmlSpace="preserve"
        >
          <style type="text/css">
            .webcam-st0{'{'}fill:#3F4A4A;{'}'}
            .webcam-st1{'{'}fill:#323C3C;{'}'}
            .webcam-st2{'{'}fill:#E1EBF0;{'}'}
            .webcam-st3{'{'}fill:#CDE1E6;{'}'}
            .webcam-st5{'{'}opacity:0.51;fill:#004FAC;enable-background:new ;
            {'}'}
          </style>
          <g id="Group_71" transform="translate(-438.119 -490.32)">
            <g id="web-camera_1_" transform="translate(438.119 490.32)">
              <path
                id="Path_52"
                className="webcam-st0"
                d="M415,486.6l-30.2-23.8c-30.8-23-48.9-57.1-49.6-93.4c-1-9-9.8-15.6-19.7-14.9H197.3
			c-9.9-0.7-18.7,5.9-19.7,14.9c-0.6,36.2-18.8,70.4-49.6,93.4l-30.2,23.8c-5.4,3.9-7.1,10.7-4,16.3c3.7,5.9,10.7,9.4,18.1,9.1
			h288.8c7.4,0.2,14.4-3.3,18.1-9.1C422,497.3,420.4,490.5,415,486.6z"
              />
              <path
                id="Path_53"
                className="webcam-st1"
                d="M384.7,462.7c-30.8-23-48.9-57.1-49.6-93.4c-1-9-9.8-15.6-19.7-14.9h-59.1v157.6h144.4
			c7.4,0.2,14.4-3.3,18.1-9.1c3.1-5.6,1.4-12.4-4-16.3L384.7,462.7z"
              />
              <path
                id="Path_54"
                className="webcam-st2"
                d="M256.4,390c-141.2,0-256-87.5-256-195s114.8-195,256-195s256,87.5,256,195S397.6,390,256.4,390z
			"
              />
              <path
                id="Path_55"
                className="webcam-st3"
                d="M256.4,0.1V390c141.2,0,256-87.5,256-195S397.6,0.1,256.4,0.1z"
              />
              <path
                id="Path_56"
                className="webcam-st0"
                d="M256.4,300c-76,0-137.9-47.1-137.9-105s61.8-105,137.9-105s137.9,47.1,137.9,105
			S332.4,300,256.4,300z"
              />
              <path
                id="Path_57"
                className="webcam-st1"
                d="M394.2,195c0-57.9-61.8-105-137.9-105v210C332.4,300,394.2,252.9,394.2,195z"
              />
              <path
                id="Path_58"
                className="webcam-st5"
                d="M276.1,436.9c2,9.9-5.2,19.4-16,21.2c-10.9,1.8-21.3-4.7-23.3-14.6c-2-9.9,5.2-19.4,16-21.2
			c1.2-0.2,2.4-0.3,3.6-0.3C266.3,421.3,275.1,427.9,276.1,436.9z"
              />
              <path
                id="Path_60"
                className={classes.iconHighlightColor}
                d="M256.4,120.1c-54.3,0-98.5,33.6-98.5,75s44.2,75,98.5,75s98.5-33.6,98.5-75
			S310.7,120.1,256.4,120.1z"
              />
              <path
                id="Path_61"
                className={classes.iconColor}
                d="M354.9,195c0-41.3-44.2-75-98.5-75v150C310.7,270,354.9,236.4,354.9,195z"
              />
            </g>
          </g>
        </svg>
      );
    },
  },
  speaker: {
    on: () => {
      const classes = useStyle();

      return (
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          width="40px"
          height="40px"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 512 512"
          style={{ enableBackground: 'new 0 0 512 512' }}
          xmlSpace="preserve"
        >
          <style type="text/css">
            .st0{'{'}fill:#DFE7F4;{'}'}
            .st1{'{'}fill:#C7CFE1;{'}'}
            .st2{'{'}fill:#474F54;{'}'}
            .st3{'{'}fill:#32393F;{'}'}
          </style>
          <g>
            <path
              className="st0"
              d="M422.3,374.5c0,24.8-20.2,45-45,45h-61c-24.6,0-46.3,12.1-60,30.5c-9.3,12.5-15,28.8-15,45.5v15h15h15v-15
		c0-24.8,20.2-46,45-46h61c41.4,0,75-33.6,75-75H422.3z"
            />
            <path
              className="st1"
              d="M271.3,495.5c0-24.8,20.2-46,45-46h61c41.4,0,75-33.6,75-75h-30c0,24.8-20.2,45-45,45h-61
		c-24.6,0-46.3,12.1-60,30.5v60.5h15V495.5z"
            />
            <path
              className="st2"
              d="M256.3-1.5c-107.7,0-196,88.3-196,196v45h34.4l25.6-30v-15c0-74.4,61.6-136,136-136s136,61.6,136,136v15
		l31.9,30h28.1v-45C452.3,86.8,364-1.5,256.3-1.5z"
            />
            <path
              className="st3"
              d="M392.3,194.5v15l31.9,30h28.1v-45c0-107.7-88.3-196-196-196v60C330.8,58.5,392.3,120.1,392.3,194.5z"
            />
            <path
              className={classes.iconColor}
              d="M467.3,389.5h-75v-180h75c24.8,0,45,20.2,45,45v90C512.3,369.4,492.2,389.5,467.3,389.5z"
            />
            <path
              className={classes.iconHighlightColor}
              d="M120.3,389.5h-75c-24.8,0-45-20.2-45-45v-90c0-24.8,20.2-45,45-45h75V389.5z"
            />
          </g>
        </svg>
      );
    },
    off: () => {
      const classes = useStyle();

      return (
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          width="40px"
          height="40px"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 512 512"
          style={{ enableBackground: 'new 0 0 512 512' }}
          xmlSpace="preserve"
        >
          <style type="text/css">
            .st0{'{'}fill:#DFE7F4;{'}'}
            .st1{'{'}fill:#C7CFE1;{'}'}
            .st2{'{'}fill:#474F54;{'}'}
            .st3{'{'}fill:#32393F;{'}'}
            .st4{'{'}fill:#004FAC;{'}'}
          </style>
          <g>
            <path
              className="st0"
              d="M422.3,374.5c0,24.8-20.2,45-45,45h-61c-24.6,0-46.3,12.1-60,30.5c-9.3,12.5-15,28.8-15,45.5v15h15h15v-15
		c0-24.8,20.2-46,45-46h61c41.4,0,75-33.6,75-75H422.3z"
            />
            <path
              className="st1"
              d="M271.3,495.5c0-24.8,20.2-46,45-46h61c41.4,0,75-33.6,75-75h-30c0,24.8-20.2,45-45,45h-61
		c-24.6,0-46.3,12.1-60,30.5v60.5h15V495.5z"
            />
            <path
              className="st2"
              d="M256.3-1.5c-107.7,0-196,88.3-196,196v45h34.4l25.6-30v-15c0-74.4,61.6-136,136-136s136,61.6,136,136v15
		l31.9,30h28.1v-45C452.3,86.8,364-1.5,256.3-1.5z"
            />
            <path
              className="st3"
              d="M392.3,194.5v15l31.9,30h28.1v-45c0-107.7-88.3-196-196-196v60C330.8,58.5,392.3,120.1,392.3,194.5z"
            />
            <path
              className={classes.iconColor}
              d="M467.3,389.5h-75v-180h75c24.8,0,45,20.2,45,45v90C512.3,369.4,492.2,389.5,467.3,389.5z"
            />
            <path
              className={classes.iconHighlightColor}
              d="M120.3,389.5h-75c-24.8,0-45-20.2-45-45v-90c0-24.8,20.2-45,45-45h75V389.5z"
            />
          </g>
        </svg>
      );
    },
  },
};
