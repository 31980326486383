/* eslint-disable react/no-unescaped-entities */
/* eslint-disable import/no-unresolved */
import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import _ from 'lodash';
import 'popper.js';
import Promise from 'bluebird';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import VerficationDefaultIcon from './icons/verificationDefaultIcon';
import '../../assets/styles/main.scss';

import { createTheme, ThemeProvider } from '@material-ui/core';
import AudioAnalyser from '../audio/audio-analizer';

import WebcamStep from './webcam-step';
import BrowserStep from './browser-step';
import AudioCheck from './audio-check';
import TabSteps from './tab-steps';

import { MICROPHONE, SPEAKERS, DEVICE, WEBCAM, STEPS } from './constants';

// TODO: remove these imports before mergin into MASTER import GlobalFonts from '../../assets/fonts/fonts';

const baseState = {
  currentCheck: STEPS[0],
  error: null,
  // userMedia
  audioTrack: null,
  audioStream: null,
  // equipmentCheck:
  speakerVerified: false,
  microphoneVerified: false,
  connectionVerified: false,
  videoVerified: false,
  // audio confirm
  showAudioConfirmMessage: false,
  showMicConfirmMessage: false,
  initEquipmentCheck: true,
  microphoneStep: 2,
  // "Seems like you are stuck?" error
};

const styles = (theme) => ({
  '@keyframes text-focus-in': {
    '0%': {
      filter: 'blur(12px)',
      opacity: '0',
    },
    '100%': {
      filter: 'blur(0px)',
      opacity: '1',
    },
  },
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: 'red',
  },
  panelList: {
    flex: 1,
    height: '48%',
    width: '835px'
  },
  formControl: {
    margin: '0 auto 2rem',
    display: 'block',
  },
  mainContainer: {
    animation: 'text-focus-in 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both',
    animationName: '$text-focus-in',
  },

  card: {
    boxShadow: '0px 0px 10px -1px rgba(0,0,0,0.75)',
    border: `5px solid ${theme.borderColor}`,
    height: '100%',
    maxWidth: '1050px',
    maxHeight: '600px',
    minWidth: '770px',
    minHeight: '615px',
  },

  mobile: {
    maxHeight: '100% !important',
    border: `0px solid ${theme.borderColor} !important`,
  },

  headerStyle: {
    textAlign: 'center',
    height: '30px',
    margin: 'auto',
  },
  headerFont: {
    fontSize: '40px',
    fontWeight: '400',
    color: theme.headerTextColor,
    textAlign: 'center',
    margin: 'auto',
  },
  mainPanel: {
    background: theme.background,
  },
  pStyle: {
    fontSize: 'x-large',
    fontWeight: '300',
  },
});

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    height: '25px',
    fontSize: '17px',
    borderRadius: 4,
    width: '200px',
    position: 'relative',
    background: 'transparent',
    border: '1px solid #97979761',
    padding: '10px 26px 10px 12px',
    color: '#000000',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      background: 'transparent',
    },
  },
}))(InputBase);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

class EquipmentCheckWidget extends React.Component {
  constructor(props) {
    super(props);

    this.state = _.cloneDeep(baseState);

    this.audioRef = React.createRef();
    this.canvasRef = React.createRef();
    this.nextRef = React.createRef();
    this.paneRefs = {};
    this.navRef = React.createRef();
    this.internetRef = React.createRef();

    this.baseState = _.cloneDeep(_.omit(this.state, ['baseState']));
    this.primaryColor = this.props.primaryColor ? this.props.primaryColor : '#DFDFDF'
    this.secondaryColor = this.props.secondaryColor ? this.props.secondaryColor : '#507F91';
    this.background = this.props.background ? this.props.background : '#507F91';
  }

  async componentDidMount() {
    const detectrtc = await import('detectrtc');
    const { default: WebRTC } = await import('../../utils/WebRTC');
    const load = Promise.promisify(detectrtc.load);

    await load();
    this.WebRTC = WebRTC;

    if (detectrtc.isMobileDevice) {
      return this.setState({
        detectrtc,
        isMobileDevice: detectrtc.isMobileDevice,
        webcamDevices: detectrtc.videoInputDevices,
        audioDevices: detectrtc.audioOutputDevices,
        microphones: detectrtc.audioInputDevices,
        devices: detectrtc.MediaDevices,
      });
    }

    if (
      detectrtc.audioOutputDevices.length === 0 &&
      !detectrtc.browser.isSafari &&
      !detectrtc.isMobileDevice
    ) {
      if (detectrtc.browser.isFirefox) {
        // console.debug('there is a bug in detectrtc with output from firefox, this will just bypass it until we fix it');

        const fakeFireFoxDefault = {
          deviceId: 'SSAmymwskCw3b1hOUS8mqZdYb0G5FDe0K1WV4aJz9g0=',
          groupId: 'lFCoIHZSP2WUNDTFNoHiKRNtnayqTpm1z78i/OEVyts=',
          id: 'SSAmymwskCw3b1hOUS8mqZdYb0G2FDe0K1WS4aJz9g2=',
          isCustomLabel: true,
          kind: 'audiooutput',
          label: 'default',
        };

        return this.setState({
          detectrtc,
          isMobileDevice: detectrtc.isMobileDevice,
          webcamDevices: detectrtc.videoInputDevices,
          audioDevices:
            detectrtc.audioOutputDevices &&
              detectrtc.audioOutputDevices.length > 0
              ? detectrtc.audioOutputDevices
              : [fakeFireFoxDefault],
          microphones: detectrtc.audioInputDevices,
          devices: detectrtc.MediaDevices,
        });
      }

      return this.setState({
        error: {
          message:
            'No audio output devices found. Please continue on a device with audio output.',
          showHomeBtn: true,
        },
      });
    }

    await this.setState({
      detectrtc,
      isMobileDevice: detectrtc.isMobileDevice,
      webcamDevices: detectrtc.videoInputDevices,
      microphones: detectrtc.audioInputDevices,
      devices: detectrtc.MediaDevices,
    });

    this.setAudioDevices();

    return true;
  }

  componentDidUpdate(prevPros) {
    if (
      prevPros.resetSteps !== this.props.resetSteps &&
      this.props.resetSteps === true
    ) {
      this.resetEquipmentCheck();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.state.stuckTimer);
  }

  setAudioDevices = () => {
    if (this.state.detectrtc.browser.isSafari) {
      const safariAudioDevices = _.filter(
        // eslint-disable-next-line react/no-access-state-in-setstate
        this.state.detectrtc.MediaDevices,
        (d) => d.kind === 'audioinput'
      );

      this.setState({ audioDevices: safariAudioDevices });

      return;
    }

    // eslint-disable-next-line react/no-access-state-in-setstate
    this.setState({ audioDevices: this.state.detectrtc.audioOutputDevices });
  };

  setVideoDevices = () => {
    if (this.state.detectrtc.browser.isSafari) {
      const safariAudioDevices = _.filter(
        // eslint-disable-next-line react/no-access-state-in-setstate
        this.state.detectrtc.MediaDevices,
        (d) => d.kind === 'videoinput'
      );

      this.setState({ webcamDevices: safariAudioDevices });

      return;
    }

    // eslint-disable-next-line react/no-access-state-in-setstate
    this.setState({ webcamDevices: this.state.detectrtc.videoInputDevices });
  };

  handleMicCheck = () => {
    this.handleMicToggle();

    $('.equipment-check-content').find('.test-hidden-screen').show();
  };

  handleMicToggle = async () => {
    const { getMicrophone, stopMicrophone } = this.WebRTC.UserMedia;

    if (this.state.microphone && this.state.microphones.length === 0) {
      return this.setState({
        error: {
          message:
            'No microphone devices found. Please continue on a device with a microphone.',
          showHomeBtn: true,
        },
      });
    }

    if (!this.state.audioStream) {
      try {
        const audio = await getMicrophone();

        await this.setState({
          audioStream: audio,
          microphoneVerified: true,
          showMicConfirmMessage: true,
        });
      } catch (err) {
        await this.setState({
          microphoneVerified: false,
          error: {
            message:
              'Unable to access microphone device. Please try again on a different device with access to a microphone or refresh the page to try again.',
            showHomeBtn: true,
          },
        });
      }
    } else {
      await stopMicrophone(this.state.audioStream);
      await this.setState({ audioStream: null });
    }

    return true;
  };

  setRef = (ref) => {
    if (!ref) {
      return;
    }

    this.paneRefs = {
      ...this.paneRefs,
      [_.camelCase(ref.id)]: ref,
    };
  };

  handleNext = async () => {
    this.setState((state) => {
      const nextStep =
        STEPS[STEPS.indexOf(state.currentCheck) + 1] || 'complete';

      if (nextStep === MICROPHONE) {
        this.handleMicCheck();
      }

      if (state.currentCheck === MICROPHONE) {
        // To turn off the "audio only" restriction to userMediaDevices
        this.handleMicToggle();
      }

      this.props.onSuccess(state.currentCheck.toLowerCase());

      if (nextStep === 'complete') {
        this.props.onComplete(true);
      }

      return { initEquipmentCheck: false, currentCheck: nextStep };
    });
  };

  handleFail = async () => {
    this.props.onFail(this.state.currentCheck.toLowerCase());
  };

  resetEquipmentCheck = async () => {
    if (this.state.initEquipmentCheck === false) {
      $(this.navRef.current).children().removeClass('active');
      $(this.internetRef.current)
        .children('.test-internet-hidden')
        .removeClass('show-message');

      $('.tab-content').children().removeClass('show active passed');
      this.setState(
        (state) => ({ ...state, ...baseState }),
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => { }
      );
    }
  };

  render() {
    const { webcamDevices } = this.state;
    const { modelsUrl } = this.props;
    const { classes } = this.props;
    const currentCheck = this.state.currentCheck;

    const customTheme = createTheme({
      palette: {
        primary: {
          main: this.primaryColor
        },
        secondary: {
          main: this.secondaryColor
        },
        background: {
          paper: this.secondaryColor
        }
      }
    });


    // currentCheck = 'MICROPHONE';

    return (
      <ThemeProvider theme={customTheme}>
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ height: '100%' }}
          className={classes.mainContainer}
        >
          <Grid
            className={clsx({
              [classes.card]: this.props.screenSize !== 'small',
              [classes.mobile]: this.props.screenSize === 'small',
            })}
            container
            item
            xs={12}
          >
            <Grid container item xs={12}>
              <Grid container item xs={12} sm={3}>
                <TabSteps
                  theme={customTheme}
                  currentCheck={this.state.currentCheck}
                  primaryColor={this.primaryColor}
                  secondaryColor={this.secondaryColor}
                  background={this.secondaryColor}
                />
              </Grid>
              <Grid xs={12} container item sm={9} className={classes.mainPanel}>
                <TabPanel
                  value={currentCheck}
                  index={DEVICE}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  {/* Browser */}
                  <div style={{ height: '100%' }}>
                    <BrowserStep
                      flags={this.props.flags}
                      theme={customTheme}
                      primaryColor={this.primaryColor}
                      secondaryColor={this.secondaryColor}
                      customBackground={this.props.customBackground}
                      onFail={(err) => {
                        this.handleFail(err);
                        this.props.onComplete(false);
                      }}
                      onSuccess={(evt) => this.handleNext(evt)}
                    />
                  </div>
                </TabPanel>
                <TabPanel
                  value={currentCheck}
                  index={MICROPHONE}
                  style={{ width: '100%', height: '100%' }}
                >
                  {/* Microphone */}
                  <Grid
                    container
                    item
                    xs={12}
                    justify="space-between"
                    className={classes.panelList}
                    direction="column"
                  >
                    <h3
                      className={classes.headerFont}
                      style={{ margin: '2rem 0 0' }}
                    >
                      IS YOUR MICROPHONE ON?
                    </h3>
                    <p className={classes.pStyle} style={{ textAlign: 'center' }}>
                      Pick the microphone you will be using for the notarization.
                    </p>
                    <FormControl className={classes.formControl}>
                      <NativeSelect
                        id="demo-customized-select-native"
                        onChange={async (evt) => {
                          if (this.audioRef && this.audioRef.current) {
                            if (!this.audioRef.current.paused) {
                              this.audioRef.current.pause();
                              await this.audioRef.current.setSinkId(
                                evt.target.value
                              );

                              return this.audioRef.current.play();
                            }

                            if (_.isFunction(this.audioRef.current.setSinkId)) {
                              return this.audioRef.current.setSinkId(
                                evt.target.value
                              );
                            }
                          }

                          this.audioAnalyser.resetComponent();

                          return true;
                        }}
                        input={<BootstrapInput />}
                      >
                        {_.map(this.state.microphones, (dev, index) => (
                          <option
                            key={dev.deviceId || index}
                            value={dev.deviceId}
                          >
                            {dev.label || dev.deviceId}
                          </option>
                        ))}
                      </NativeSelect>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      height: '4%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Divider
                      variant="middle"
                      style={{
                        height: '2px',
                        width: '96%',
                        backgroundColor: '#97979761',
                      }}
                    />
                  </Grid>
                  <div
                    className="equipment-check-content"
                    style={{ width: '100%', height: '48%' }}
                  >
                    {this.state.audioStream &&
                      this.state.showMicConfirmMessage ? (
                      <AudioAnalyser
                        ref={(audioAnalyser) => {
                          this.audioAnalyser = audioAnalyser;
                        }}
                        onSuccess={(evt) => this.handleNext(evt)}
                        onFail={this.props.onFail}
                        theme={customTheme}
                        flags={this.props.flags}
                        audio={this.state.audioStream}
                      />
                    ) : null}
                  </div>
                </TabPanel>
                <TabPanel
                  value={currentCheck}
                  index={WEBCAM}
                  style={{ height: '100%' }}
                >
                  {currentCheck === WEBCAM ? (
                    <WebcamStep
                      currentCheck={currentCheck}
                      flags={this.props.flags}
                      theme={customTheme}
                      handleNext={(evt) => this.handleNext(evt)}
                      primaryColor={this.primaryColor}
                      secondaryColor={this.secondaryColor}
                      onFail={() => this.handleFail()}
                      isActive={currentCheck === WEBCAM}
                      modelsUrl={modelsUrl}
                      webcamDevices={webcamDevices}
                    />
                  ) : null}
                </TabPanel>
                <TabPanel
                  value={currentCheck}
                  index={SPEAKERS}
                  style={{ height: '100%', width: '100%' }}
                >
                  <AudioCheck
                    isMobile={_.get(this.state.detectrtc, 'isMobileDevice')}
                    isSafari={_.get(this.state.detectrtc, 'browser.isSafari')}
                    cdnUrl={this.props.cdnUrl}
                    isActive={currentCheck === SPEAKERS}
                    audioDevices={this.state.audioDevices}
                    handleNext={(evt) => this.handleNext(evt, 'complete')}
                    primaryColor={this.primaryColor}
                    secondaryColor={this.secondaryColor}
                    theme={customTheme}
                    onFail={this.handleFail}
                    flags={this.props.flags}
                  />
                </TabPanel>
                <TabPanel
                  value={currentCheck}
                  index="complete"
                  style={{ height: '100%' }}
                >
                  <Grid
                    container
                    item
                    style={{
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Grid
                      container
                      item
                      xs={12}
                      direction="column"
                      style={{ padding: '1rem', height: '80%' }}
                    >
                      <Grid container item xs={12} className={classes.panelList}>
                        <Grid container item justify="center">
                          <h2 className={classes.headerFont}>YOU'RE ALL SET!</h2>
                        </Grid>
                        <Grid container item justify="center" alignItems="center">
                          <p className={classes.pStyle}>
                            Your device is compatible and able to connect to a
                            notary.
                          </p>
                        </Grid>
                        <Grid container item justify="center" alignItems="center">
                          <VerficationDefaultIcon
                            length={160}
                            theme={customTheme}
                            animation="middle-out"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </TabPanel>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ThemeProvider>
    );
  }
}

EquipmentCheckWidget.propTypes = {
  modelsUrl: PropTypes.string.isRequired,
  onComplete: PropTypes.func,
  onSuccess: PropTypes.func,
  onFail: PropTypes.func,
  resetSteps: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  flags: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.object,
  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string,
  customBackground: PropTypes.string
};

EquipmentCheckWidget.defaultProps = {
  onComplete: () => undefined,
  onSuccess: () => undefined,
  onFail: () => undefined,
  theme: {},
  flags: {},
  resetSteps: false,
  primaryColor: undefined,
  secondaryColor: undefined,
  customBackground: undefined
};

const EqCheck = withStyles(styles)(EquipmentCheckWidget);

export default function WithMediaQuery(props) {
  const largeScreen = useMediaQuery('(min-width:870px)');
  const mediumScreen = useMediaQuery('(min-width:600px)');

  let screenSize = 'small';

  if (largeScreen) {
    screenSize = 'large';
  } else if (mediumScreen) {
    screenSize = 'medium';
  }

  return <EqCheck {...props} screenSize={screenSize} />;
}
