/* eslint-disable import/no-unresolved */
// import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import svg from './tab-step-svg';
import { STEPS } from './constants';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 224,
  },
  indicator: {
    left: '0px',
    backgroundColor: theme.primaryColor,
    width: '10px',
  },
  tabs: {
    borderRight: '2px solid #97979761',
    width: '100%',
    background: theme.background,
    color: theme.tabTextColor,
  },
  tabSelected: {
    backgroundColor: `${theme.primaryColor}1A`,
  },
  individualTab: {
    padding: '0px 0 0 14px',
    opacity: 'unset',
  },
  individualTabMedium: {
    opacity: 'unset',
    padding: '0px 0 0 0px !important',
  },
  hidden: {
    display: 'none',
  },

  tabsContainer: {
    height: '100%',
  },
  small: {
    fontSize: '12px',
  },
  medium: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    // padding: 0,
  },
  large: {
    justifyContent: 'flex-start',
    display: 'inline-flex',
    maxWidth: '100%',
    alignItems: 'center',
    flexDirection: 'unset',
  },
}));

export default function VerticalTabs(props) {
  const useCustomStyles = makeStyles(() => ({
    indicator: {
      left: '0px',
      backgroundColor: props.background,
      width: '10px',
    },
  }));

  const classes = useStyles();
  const customClasses = useCustomStyles();

  const lastCompletedStep = STEPS.indexOf(props.currentCheck) - 1;
  const itsCompleted = props.currentCheck === 'complete';
  const largeScreen = useMediaQuery('(max-width:870px)');
  const mediumScreen = useMediaQuery('(min-width:600px)');
  const lgSize = useMediaQuery('(min-width:870px)');

  let screenSize = 'small';

  if (largeScreen) {
    screenSize = 'large';
  } else if (mediumScreen) {
    screenSize = 'medium';
  }

  return (
    <>
      {/* {screenSize} */}
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={props.currentCheck}
        onChange={() => null}
        aria-label="Vertical tabs example"
        className={clsx({
          [classes.tabs]: true,
          [classes.hidden]: !lgSize && !mediumScreen,
        })}
        classes={{
          indicator: customClasses.indicator || classes.indicator,
          flexContainer: classes.tabsContainer,
        }}
      >
        {STEPS.map((step, index) => (
          <Tab
            disableRipple
            wrapped
            key={step}
            style={{ minHeight: '25%' }}
            classes={{
              wrapper:
                screenSize === 'medium'
                  ? classes.individualTab
                  : classes.individualTabMedium,
              root:
                screenSize === 'medium'
                  ? classes.individualTab
                  : classes.individualTabMedium,
            }}
            className={` ${classes[screenSize]} ${itsCompleted || index <= lastCompletedStep
              ? classes.tabSelected
              : null
              }`}
            label={svg({
              step,
              completed: itsCompleted || index <= lastCompletedStep,
              theme: props.theme,
            })}
            value={step}
          // icon={svg({ step, completed: itsCompleted || index <= lastCompletedStep, theme: props.theme })}
          // label={step}
          />
        ))}
      </Tabs>
    </>
  );
}
