/* To get information about available input and output devices */
export const availableIODevices = (() =>
  navigator.mediaDevices && navigator.mediaDevices.enumerateDevices())();

/* To check if "getUserMedia" is supported by user Browser */
export const hasGetUserMedia = !!(
  navigator.mediaDevices && navigator.mediaDevices.getUserMedia
);

/* Represents an audio-processing graph, each represented by an AudioNode. */
export const AudioContext = window.AudioContext || window.webkitAudioContext;

export const constraints = {
  audio: { audio: true, video: false },
  video: { video: true },
  check: { audio: true, video: true },
};
