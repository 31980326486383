/* eslint-disable */

export default function toPromise(xhr, failNon2xx = true) {
  return new Promise((resolve, reject) => {
    xhr.onload = function () {
      failNon2xx && (xhr.status < 200 || xhr.status >= 300)
        ? reject({ request: xhr })
        : resolve(xhr);
    };

    // Only triggers if the request couldn't be made at all
    xhr.onerror = function () {
      reject({ request: xhr });
    };

    xhr.send();
  });
}
