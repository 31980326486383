import React from 'react';
import { v4, validate } from 'uuid';
import { getWorker } from '../../mocks/browser';
import { useHistory } from 'react-router';
import { useLocation } from 'react-use';
import { useParams } from '@enotarylog/shared';


interface IMockGuardProps {
  history: ReturnType<typeof useHistory>,
  location: ReturnType<typeof useLocation>,
  params: Record<string, string>
}

class MockGuard extends React.Component<IMockGuardProps> {
  state = {
    mounted: false
  }
  async componentDidMount() {

    if (this.props.params.mock === 'true') {

      let requestId = this.props.params.requestId ? this.props.params.requestId : localStorage.getItem('requestId');
      if (!requestId || !validate(requestId)) {
        requestId = v4();
        localStorage.setItem('requestId', requestId);
      }
      let nsId = this.props.params.nsId ? this.props.params.nsId : localStorage.getItem('nsId');
      if (!nsId || !validate(nsId)) {
        nsId = v4();
        localStorage.setItem('nsId', nsId);
      }

      const isNotary = this.props.params.type == 'notary';
      const worker = getWorker(isNotary, requestId, nsId);

      await worker.start();
    }

    return this.setState({ mounted: true })


  }
  render() {
    if (!this.state.mounted) {
      return <></>
    }
    return (
      this.props.children
    )
  }
}

export default function MockGuardWrapper({ children }) {
  const history = useHistory();
  const location = useLocation();
  const params = useParams<{ requestId?: string, nsId?: string, mock?: any, type?: 'consumer' | 'notary' }>();

  return (
    <MockGuard
      params={params}
      location={location}
      history={history}
    >
      {children}
    </MockGuard>
  );
}
