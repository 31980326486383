import { Component, createContext, useContext, useEffect } from 'react';
import { validate } from 'uuid';
import { withFirebase, withQuery } from '@enotarylog/shared';
import _ from 'lodash';
const log = console.log

export const PresenceContext = createContext(null);

export function usePresence(props = {}) {
  const ctx = useContext(PresenceContext);

  if (!ctx) {
    throw new Error('usePresence must be used within a PresenceProvider');
  }

  useEffect(() => {
    if (props.page && _.isString(props.page)) {
      log('updating page to', props.page)
      ctx.updateSessionStatus(props.page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.nsId, props.page]);

  return ctx;
}

class PresenceProviderBase extends Component {

  state = {
    initialized: false,
    page: 'pending-start',
    witnesses: 0,
    sessionRef: null,
    connectionRef: null,
  };

  async componentDidUpdate() {
    log('presence provider updated', this.props.namespace);
    if (window.location?.pathname.includes('document-delivery') || window.location?.pathname.includes('payments')) {
      if (this.state.page !== 'payments') {
        await this.setState({ page: 'payments' }, async () => {
          await this.initialize();

        });

      }
    }
  }

  async componentDidMount() {
    await this.initialize();
  }


  async initialize() {
    log('presence provider called', this.props?.namespace);
    const { firebase, query } = this.props;
    const namespace = this.props.namespace || query.organizationId;
    const nsUserId = this.props.nsUserId;
    if (!validate(namespace)) {
      throw new Error('Malformed organization id');
    }

    const sessionRef = firebase.database().ref(`/organization/${namespace}/sessions/${nsUserId}`);

    // ref: https://firebase.google.com/docs/firestore/solutions/presence#using_presence_in_realtime_database
    const connectionRef = firebase.database().ref('.info/connected');

    connectionRef.on('value', async (snapshot) => {
      // If we're not currently connected, don't do anything.
      if (snapshot.val() === false) {
        log('firebase not connected 🔥!');
        return;
      }

      log('firebase connected 🔥!');
      // If we are currently connected, then use the 'onDisconnect()'
      // method to add a set which will only trigger once this
      // client has disconnected by closing the app,
      // losing internet, or any other means.
      await sessionRef.onDisconnect().remove();

      // The promise returned from .onDisconnect().set() will
      // resolve as soon as the server acknowledges the onDisconnect()
      // request, NOT once we've actually disconnected:
      // https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect

      // We can now safely set ourselves as 'online' knowing that the
      // server will mark us as offline once we lose connection.
      const currSnap = await sessionRef.once('value');
      const isPaymentsPage = (window.location.pathname.includes('document-delivery') || window.location.pathname.includes('payments'));
      const page = isPaymentsPage ? 'payments' : this.state.page;
      log(`page: ${page}`)

      const now = +Date.now();
      const sessionData = {
        ...currSnap.val(),
        connected: true,
        witnesses: this.state.witnesses,
        updatedAt: now,
        nsId: query.nsId
      }

      if(!sessionData.createdAt){
        sessionData.createdAt = now
        sessionData.page = page
      }


      await sessionRef.update(sessionData);
      sessionRef.on('value', async (snapshot) => {
        const val = snapshot.val();
        if (val === null && window.alertOpen !== true) {
          window.alertOpen = true;
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const data = window.confirm('Session has been updated. Click okay to reload.');
          window.alertOpen = false;
          window.location.reload();
        }
      })

      this.setState({
        initialized: true,
        page,
        sessionRef,
        connectionRef,
      });

    });
  }

  updateSessionStatus = async (page) => {
    log('update session status called', page);
    if (!this.state.initialized) {
      await this.initialize();
    }

    this.setState({ page: page || this.state.page }, () => {
      const updatedAt = +Date.now()

      this.state.sessionRef.update({
        connected: true,
        page: page || this.state.page,
        updatedAt: updatedAt })
    });
  }

  disconnectSession = (page) => {
    log('disconnect session status called');
    this.setState({ page: page || this.state.page, initialized: false }, async () => {
      return Promise.all([
        this.state.sessionRef.update({page:'complete_success', completedAt: +Date()})
      ]);
    });
  }

  render() {
    return (
      <PresenceContext.Provider
        value={{
          sessionRef: this.state.sessionRef,
          connectionRef: this.state.connectionRef,
          updateSessionStatus: this.updateSessionStatus,
          disconnectSession: this.disconnectSession,
        }}
      >
        {this.state.initialized && this.props.children}
      </PresenceContext.Provider>
    )
  }
}

export const PresenceProvider = withQuery(withFirebase(PresenceProviderBase));


export default usePresence;
