import { LoadingScreen, useFirebase, useQuery } from '@enotarylog/shared';
import React, { useReducer, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { useAppState } from '../useAppState';
import createServer from './server';

export const ServerCtx = React.createContext();

const reducer = (state = null, action) => {
  switch (action.type) {
    case 'set':
      return action.payload;
    default:
      return state;
  }
};


// Provider
export function ServerProvider({ firebase, config, children }) {
  const appState = useAppState();
  const [server, dispatch] = useReducer(reducer, null);


  useEffectOnce(() => {
    const importServer = async () => {
      if (server) {
        dispatch({ type: 'set', payload: server });

        return server;
      }

      const _server = await createServer(firebase, { ...config });

      window.server = _server;

      dispatch({ type: 'set', payload: _server });

      return _server;
    };

    if (!server && firebase) {
      importServer()
        .then((server) => {
          server.getLoadedDocs()
            .then(appState.setLoadedDocs);
        });
    }

    // return () => {
    //   if (server) {
    //     // return server.markAsDisconnected()
    //   }
    // };
  }, [server, firebase, config.userId, config]);


  return (
    <ServerCtx.Provider value={server}>
      {
        (!server) ? (
          <></>
        ) : children
      }
    </ServerCtx.Provider>
  );
}


// hook
export const useServer = () => {
  const server = useContext(ServerCtx);

  if (!server) {
    throw new Error('useServer must be called within <ServerProvider/> context');
  }

  return server;
};


// HOC
export const withServerProvider = (useConfig) => (Component) => function WithServerProvider({ firebase, ...props }) {
  const conf = useConfig(props);


  return (
    <ServerProvider
      firebase={conf.firebase}
      config={conf}
    >
      <Component {...props} />
    </ServerProvider>
  );
};


export const WithServerProviderNotary = ({ children, ...props }) => {
  const params = useParams();
  const nsId = useQuery('nsId');
  const appState = useAppState();
  const firebase = useFirebase();

  /*
    TODO: This is a temporary measure so Notarize Now sessions can work properly with the new
    socket.io approach in notary-room-v2. James told me that in the future the signer will no longer
    go through the notarize app, so this won't be needed at that point.
  */
  const assignedToOrg = appState.notarySession?.request?.assignedToOrg;
  const assignedTo = appState.notarySession?.request?.assignedTo;

  const isNotarizeNow =
    appState.notarySession?.senderId === null
    && assignedToOrg === null
    && assignedTo === null;

  const serverProps = {
    firebase,
    nsId: nsId,
    requestId: params.requestId,
    rtdbNamespace: appState?.user?.organizationId,
    signers: appState?.signers,
    signerLocation: appState?.signerLocation,
    userId: appState?.userId,
    user: appState?.user,
    runId: appState?.getRunId(),
    userType: appState?.userType,
    isNotarizeNow, // For now, we want to now if the session is from Notarize Now. This won't be necessary later.
  };

  return (
    <ServerProvider
      firebase={firebase}
      config={{ ...serverProps }}
    >
      {children}
    </ServerProvider>
  );
};


export const withUseServer = (Component) => (props) => {
  const server = useServer();

  return (
    <Component
      {...props}
      server={server}
    />
  );
};

