import firebase from 'firebase/app';

import { rest } from 'msw'
import { getMockResponses } from './data/get-ns-info'
import { firebaseConfig } from '../config';
const nsId = ':nsId';
const requestId = ':requestId';


export const getHandlers = (requestId: string, nsId: string) => {
  const { getNotaryImages, getCounties, getDocuments, getNotarySessionInfo, getParticipants, getPermissions, getUser, getVerification, getPariticantById, startNotarySession } = getMockResponses(requestId, nsId);
  if (firebase.apps.length == 0) {
    firebase.initializeApp(firebaseConfig);
  }

  const refPath = `/organization/8d976a23-b865-4fcd-9165-ddc0aedaf614/rooms/${nsId}`;
  console.log('refPath', refPath);
  const ref = firebase.database()
    .ref(refPath);


  ref.set(null);

  return [
    rest.patch('/api/request/:requestId/notary-session/:nsId/journal', (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json({})
      );
    }),
    rest.get('/api/request/:requestId/notary-session/:nsId', async (req, res, ctx) => {
      console.log('firebase.apps.length', firebase.apps.length)
      const statusRef = await firebase.database()
        .ref(`/organization/8d976a23-b865-4fcd-9165-ddc0aedaf614/rooms/${req.params.nsId}/status`);
      const val = await firebase.database()
        .ref(`/organization/8d976a23-b865-4fcd-9165-ddc0aedaf614/rooms/${req.params.nsId}/status`)
        .once('value');

      console.log('room status', val.val());

      const isComplete = (window as any).isComplete;
      if (!isComplete) {
        await statusRef.set('ready')
      }

      return res(
        ctx.status(200),
        ctx.json({
          data: {
            ...getNotarySessionInfo.data,
            status: isComplete ? 'complete_success' : val.val()
          }
        })
      )
    }),
    rest.get('/api/request/:requestId/notary-session/:nsId/documents', (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json(getDocuments)
      )
    }),
    rest.get('/api/request/:requestId/notary-session/:nsId/participants', (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json(getParticipants)
      )
    }),

    rest.get('/api/request/:requestId/notary-session/:nsId/participant/:participantId', (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json(getPariticantById)
      )
    }),
    rest.post('/api/request/:requestId/notary-session/:nsId/start', async (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json(startNotarySession)
      )
    }),
    rest.post('/api/request/:requestId/notary-session/:nsId/stop', async (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json({
          data: {
            ...startNotarySession.data,
            status: 'ready'
          }
        })
      )
    }),
    rest.get('/api/request/:nsId/participants/:participantId/verification', (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json(getVerification)
      )
    }),
    rest.get('/api/user', (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json(getUser)
      )
    }),
    rest.get('/api/permissions', (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json(getPermissions)
      )
    }),
    rest.get('/api/internal/notary-images', (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json(getNotaryImages)
      )
    }),
    rest.get('/api/counties', (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json(getCounties)
      )
    }),
    rest.patch('/api/internal/notarization/event', (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json({})
      )
    }),
    rest.put('/api/organization/notary/8590bce1-16dc-46cc-bec5-a2d979e41588/counties', (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json({})
      )
    }),
    rest.get('/api/request/:requestId/participants/:participantId/verification', (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json(getVerification)
      )
    }),
    rest.patch('/api/compliance/:nsId/kba-check', (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.text('')
      )
    }),
    rest.get('/api/cert', async (req, res, ctx) => {
      const data = await fetch('/static/esign_cert.pfx').then((resp) => resp.arrayBuffer());
      return res(
        ctx.set('Content-Length', data.byteLength.toString()),
        ctx.set('Content-Type', 'application/x-pkcs12'),
        // Respond with the "ArrayBuffer".
        ctx.body(data),
      )
    }),
    rest.get('/api/cert-info', async (req, res, ctx) => {
      return res(
        ctx.json({
          certPass: 'password'
        }),
      )
    }),

    rest.post('/api/request/:requestId/notary-session/:nsId/documents/:docId/revision', async (req, res, ctx) => {
      return res(
        ctx.json({
          completed: true,
          createdAt: "2022-03-28T16:58:34.694Z",
          docId: req.params.docId,
          gcsFlattendDocId: `${req.params.nsId}/2022-03-28_04_58_34_513-Affidavit of Identity Confirmation-21-132-.pdf`,
          gcsRefId: `${req.params.nsId}/2022-03-28_04_58_34_513-Affidavit of Identity Confirmation-21-132-.pdf`,
          id: "956dd255-cbb9-4e78-8153-445db0aebba4",
          redaction: false,
          requestUserId: null,
          updatedAt: "2022-03-28T16:58:34.694Z",
          versionNumber: 2
        }),
      )
    }),
    rest.post('/api/request/:requestId/notary-session/:nsId/finalize', async (req, res, ctx) => {
      (window as any).isComplete = true;
      return res(
        ctx.json({
          msg: 'ok'
        }),
      )
    }),

    rest.get('*', (req, res, ctx) => {
      console.log(req.url.href)
      // Notice no `return res()` statement
    })
  ]


}
