/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles((theme) => ({
  iconColor: {
    fill: theme.iconColor,
  },
}));

export default {
  drums: function Drums() {
    const classes = useStyle();

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100px"
        height="100px"
        viewBox="0 0 491.199 360.778"
      >
        <g
          id="drum-set_1_"
          data-name="drum-set (1)"
          transform="translate(0 -0.102)"
        >
          <path
            id="Path_1600"
            data-name="Path 1600"
            d="M109.6,80.879h112a8,8,0,0,1,8,8v104a8,8,0,0,1-8,8h-112a8,8,0,0,1-8-8v-104A8,8,0,0,1,109.6,80.879Zm0,0"
            fill="#323c3c"
          />
          <path
            id="Path_1601"
            data-name="Path 1601"
            d="M269.6,80.879h112a8,8,0,0,1,8,8v104a8,8,0,0,1-8,8h-112a8,8,0,0,1-8-8v-104A8,8,0,0,1,269.6,80.879Zm0,0"
            fill="#3f4a4a"
          />
          <path
            id="Path_1602"
            data-name="Path 1602"
            d="M366.4,350.48l-28.8-28.8a128,128,0,1,0-183.2,0l-28.8,30.4-8,8.8H140l25.6-28a127.2,127.2,0,0,0,159.2,0l28,27.2h24Zm0,0"
            fill="#3f4a4a"
          />
          <path
            id="Path_1603"
            data-name="Path 1603"
            d="M325.6,332.879l28,27.2h24l-10.4-10.4-28.8-28.8a128,128,0,0,0-92.8-216v256A127.177,127.177,0,0,0,325.6,332.879Zm0,0"
            fill="#323c3c"
          />
          <path
            id="Path_1604"
            data-name="Path 1604"
            d="M245.6,136.879a96,96,0,1,0,96,96A96,96,0,0,0,245.6,136.879Zm0,0"
            fill="#e1ebf0"
          />
          <path
            id="Path_1605"
            data-name="Path 1605"
            d="M429.6,316.879v-308h-16v308l-47.2,33.6-12.8,9.6.8.8h24.8l34.4-24.8v24.8h16v-24.8l34.4,24.8h27.2Zm0,0"
            fill="#323c3c"
          />
          <path
            id="Path_1606"
            data-name="Path 1606"
            d="M124.8,352.078l-47.2-35.2v-308h-16v308l-61.6,44H27.2l34.4-24v24h16v-24l32,24h27.2Zm0,0"
            fill="#3f4a4a"
          />
          <path
            id="Path_1607"
            data-name="Path 1607"
            d="M17.574,23.238,23.281,8.293l102.387,39.1L119.961,62.34Zm0,0"
            fill="#ffda45"
          />
          <path
            id="Path_1608"
            data-name="Path 1608"
            d="M366.711,39.2,469.1.1,474.8,15.047l-102.387,39.1Zm0,0"
            fill="#ffbc36"
          />
          <path
            id="Path_1609"
            data-name="Path 1609"
            d="M341.6,232.879a96,96,0,0,0-96-96v192A96,96,0,0,0,341.6,232.879Zm0,0"
            fill="#cde1e6"
          />
        </g>
      </svg>
    );
  },
  horns: function Horns() {
    const classes = useStyle();

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100px"
        height="100px"
        viewBox="0 0 512 512"
      >
        <g id="trumpet" transform="translate(0 0)">
          <path
            id="Path_1610"
            data-name="Path 1610"
            d="M72.39,482.037,51.177,503.25A30,30,0,0,1,8.751,460.824l21.213-21.213H72.39Z"
            fill="#ffa426"
          />
          <path
            id="Path_1611"
            data-name="Path 1611"
            d="M72.39,439.61v42.426L51.177,503.25a30.088,30.088,0,0,1-42.426,0Z"
            fill="#ffa426"
          />
          <g id="Group_1711" data-name="Group 1711">
            <path
              id="Path_1612"
              data-name="Path 1612"
              d="M213.923,340.486,72.39,482.037,29.963,439.61,171.5,298.06Z"
              fill="#ffa426"
            />
            <rect
              id="Rectangle_2124"
              data-name="Rectangle 2124"
              width="200.182"
              height="30.002"
              transform="matrix(-0.707, 0.707, -0.707, -0.707, 213.935, 340.471)"
              fill="#ffa426"
            />
          </g>
          <path
            id="Path_1613"
            data-name="Path 1613"
            d="M383.628,213.207a77.947,77.947,0,0,0-23.122-16.335c26.515-16.758,61.73-49,133.855-32.88,9.333,2.121,18.243-5.94,17.606-15.485C510.059,109.685,501.573,76.594,468.48,43.5c-34.789-34.79-69.79-42.215-105-43.486a14.678,14.678,0,0,0-15.061,18.031c14,57.7-4.668,107.763-39.881,142.977l-158.25,158.25a88.207,88.207,0,0,0-26.092,68.518,89.194,89.194,0,0,0,8.7,33.73,83.164,83.164,0,0,0,17.4,25.031,89.881,89.881,0,0,0,127.279,0L383.628,340.486A89.879,89.879,0,0,0,383.628,213.207ZM235.136,404.126A30,30,0,1,1,192.71,361.7L298.776,255.634A30,30,0,0,1,341.2,298.06Z"
            fill="#ffda45"
          />
          <path
            id="Path_1614"
            data-name="Path 1614"
            d="M383.628,213.207a77.947,77.947,0,0,0-23.122-16.335c26.515-16.758,61.73-49,133.855-32.88,9.333,2.121,18.243-5.94,17.606-15.485C510.059,109.685,501.573,76.594,468.48,43.5L124.19,387.791a89.194,89.194,0,0,0,8.7,33.73,83.164,83.164,0,0,0,17.4,25.031,89.881,89.881,0,0,0,127.279,0L383.627,340.486A89.878,89.878,0,0,0,383.628,213.207ZM235.136,404.126A30,30,0,1,1,192.71,361.7L298.776,255.634A30,30,0,0,1,341.2,298.06Z"
            fill="#ffbc36"
          />
          <g id="Group_1712" data-name="Group 1712">
            <path
              id="Path_1615"
              data-name="Path 1615"
              d="M394.235,351.093,341.2,298.06l-21.213,21.213,53.033,53.033a15,15,0,0,0,21.213-21.213Z"
              fill="#ffa426"
            />
            <path
              id="Path_1616"
              data-name="Path 1616"
              d="M351.808,393.519l-53.033-53.033L277.562,361.7,330.6,414.732a15,15,0,0,0,21.213-21.213Z"
              fill="#ffa426"
            />
            <path
              id="Path_1617"
              data-name="Path 1617"
              d="M309.382,435.946l-53.033-53.033-21.213,21.213,53.033,53.033a15,15,0,1,0,21.213-21.213Z"
              fill="#ffa426"
            />
          </g>
        </g>
      </svg>
    );
  },
  piano: function Piano() {
    const classes = useStyle();

    return (
      <svg
        id="piano"
        xmlns="http://www.w3.org/2000/svg"
        width="100px"
        height="100px"
        viewBox="0 0 511.998 512"
      >
        <path
          id="Path_1595"
          data-name="Path 1595"
          d="M352,42.667A182.956,182.956,0,0,1,231.4,2.987,10.669,10.669,0,0,0,224,0H96a10.667,10.667,0,0,0-9.664,15.168l149.333,320a10.666,10.666,0,0,0,9.664,6.165H394.666C418.133,341.333,512,287.616,512,202.666A160.168,160.168,0,0,0,352,42.667Z"
          fill="#3f4a4a"
        />
        <path
          id="Path_1596"
          data-name="Path 1596"
          d="M117.333,341.333a10.667,10.667,0,0,1-8.875-16.576l128-192a10.668,10.668,0,0,1,17.75,11.84l-128,192A10.666,10.666,0,0,1,117.333,341.333Z"
          fill="#ffbc36"
        />
        <g id="Group_1710" data-name="Group 1710">
          <path
            id="Path_1597"
            data-name="Path 1597"
            d="M373.333,512a10.667,10.667,0,0,1-10.667-10.667v-64a10.667,10.667,0,0,1,21.334,0v64A10.667,10.667,0,0,1,373.333,512Z"
            fill="#3f4a4a"
          />
          <path
            id="Path_1598"
            data-name="Path 1598"
            d="M96,512a10.667,10.667,0,0,1-10.667-10.667v-64a10.667,10.667,0,0,1,21.334,0v64A10.667,10.667,0,0,1,96,512Z"
            fill="#3f4a4a"
          />
        </g>
        <path
          id="Path_1599"
          data-name="Path 1599"
          d="M458.667,320h-384A10.667,10.667,0,0,0,64,330.667,53.333,53.333,0,0,1,10.667,384,10.667,10.667,0,0,0,0,394.667v42.667A10.667,10.667,0,0,0,10.667,448h448a10.667,10.667,0,0,0,10.667-10.667V330.667A10.668,10.668,0,0,0,458.667,320Z"
          fill="#323c3c"
        />
      </svg>
    );
  },
};
