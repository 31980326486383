import { rest } from 'msw'
import { getMockResponses } from './data/consumer-ns-info';

import { firebaseConfig } from '../config';
import firebase from 'firebase/app';

// const getResponse = getMockResponses(requestId, nsId);

export const consumerHandlers = (requestId: string, nsId: string) => {

  const { getParticipantById, getStatus, idVerifyStart, idVerifyAnswers, idVerifyQuestions, doKbaCheck, getDocuments, getNotarySessionStatus, notarySessionInfo, submitNotarizationEvent, submitPinCode } = getMockResponses(requestId, nsId)();

  if (firebase.apps.length == 0) {
    firebase.initializeApp(firebaseConfig);
  }

  const refPath = `/organization/8d976a23-b865-4fcd-9165-ddc0aedaf614/rooms/${nsId}`;
  console.log('refPath', refPath);
  const ref = firebase.database()
    .ref(refPath);

  return [
    // /notarization/6d8cbb8d-df6a-4602-8e2f-4b6db7cfef81/participants/66d55e65-e774-471e-8c43-05b43ba306be/id-verify-start
    rest.get('/consumer/api/notarization/:nsId/participants/:participantId', async (req, res, ctx) => {

      const val = await firebase.database()
        .ref(`/organization/8d976a23-b865-4fcd-9165-ddc0aedaf614/rooms/${nsId}/authors/${req.params.participantId}/status`)
        .once('value');

      let status = getParticipantById.status
      if (val.val() === 'pending_kba_verify') {
        status = 'pending_doc_verify';
      }

      return res(
        ctx.status(200),
        ctx.json({
          ...getParticipantById,
          status,
        })
      )
    }),
    rest.patch('/consumer/api/notarization/:nsId/participants/:participantId/terms', async (req, res, ctx) => {
      localStorage.setItem(`${nsId}:${req.params.participantId}:kbaEsignTermsAccepted`, '1');

      return res(
        ctx.status(200),
        ctx.json({
          message: 'ok'
        })
      )
    }),
    rest.get('/consumer/api/notarization/:nsId/participants/:participantId/document-verification', async (req, res, ctx) => {

      return res(
        ctx.status(200),
        ctx.json({
          redirectUrl: 'https://www.google.com',
        })
      )
    }),
    rest.get('/consumer/api/notarization/:nsId', async (req, res, ctx) => {
      console.log(req.params.nsId);
      const nsId = (window as any).nsId = req.params.nsId;

      const val = await firebase.database()
        .ref(`/organization/8d976a23-b865-4fcd-9165-ddc0aedaf614/rooms/${nsId}/status`)
        .once('value');
      console.log('room status', val.val());
      return res(
        ctx.status(200),
        ctx.json(notarySessionInfo)
      )
    }),

    rest.get('/consumer/api/notarization/:nsId/status', async (req, res, ctx) => {
      console.log(req.params.nsId);
      const nsId = (window as any).nsId = req.params.nsId;

      const val = await firebase.database()
        .ref(`/organization/8d976a23-b865-4fcd-9165-ddc0aedaf614/rooms/${nsId}/status`)
        .once('value');
      console.log('room status', val.val());
      return res(
        ctx.status(200),
        ctx.json(getNotarySessionStatus)
      )
    }),
    rest.get('/consumer/api/notarization/:nsId/documents', (req, res, ctx) => {
      const nsId = req.params.nsId;
      console.log('nsId')
      return res(
        ctx.status(200),
        ctx.json(getDocuments)
      )
    }),
    rest.patch('/consumer/api/compliance/:nsId/kba-check', (req, res, ctx) => {
      const nsId = req.params.nsId;
      return res(
        ctx.status(200),
        ctx.text(doKbaCheck)
      )
    }),
    rest.get('/consumer/api/notarization/room/:requestId/pin-code/71718', async (req, res, ctx) => {
      const requestId = req.params.requestId;

      const termsAccepted = localStorage.getItem(`${nsId}:${submitPinCode.id}:kbaEsignTermsAccepted`);

      return res(
        ctx.status(200),
        ctx.json({
          ...submitPinCode,
          kbaEsignTermsAccepted: termsAccepted === '1'
        })
      )
    }),
    rest.patch('/consumer/api/compliance/:nsId/kba-check', (req, res, ctx) => {
      const nsId = req.params.nsId;
      return res(
        ctx.status(200),
        ctx.text(submitNotarizationEvent)
      )
    }),



    rest.post('/consumer/api/notarization/:nsId/participants/:participantId/id-verify-start', (req, res, ctx) => {
      const nsId = req.params.nsId;
      return res(
        ctx.status(200),
        ctx.json(idVerifyStart)
      )
    }),
    rest.get('/consumer/api/notarization/:nsId/participants/:participantId/id-verify-questions', (req, res, ctx) => {
      const nsId = req.params.nsId;
      return res(
        ctx.status(200),
        ctx.json(idVerifyQuestions)
      )
    }),
    rest.post('/consumer/api/notarization/:nsId/participants/:participantId/id-verify-answers', (req, res, ctx) => {
      const nsId = req.params.nsId;
      return res(
        ctx.status(200),
        ctx.json(idVerifyAnswers)
      )
    }),
    rest.patch('/consumer/api/notarization/event', (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json({})
      )
    }),
    rest.get('/consumer/api/notarization/:nsId/status', (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json(getStatus)
      )
    }),
    // pass-thru to the real api
    rest.get('*', (req, res, ctx) => {
      console.log(req.url.href)
      // Notice no `return res()` statement
    })
  ]

}
