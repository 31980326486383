/* eslint-disable import/no-unresolved */
/* eslint-disable no-undef */
// eslint-disable-next-line max-classes-per-file
import React from 'react';
import * as faceapi from 'face-api.js';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import FormControl from '@material-ui/core/FormControl';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import Divider from '@material-ui/core/Divider';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Loader from '../loader';
import VerficationDefaultIcon from './icons/verificationDefaultIcon';
import ReusableButton from './reusableButton';

const styles = (theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: 'red',
  },
  panelList: {
    flex: 1,
  },
  card: {
    boxShadow: '0px 0px 10px -1px rgba(0,0,0,0.75)',
  },
  headerStyle: {
    textAlign: 'center',
    color: theme.headerTextColor,
    fontSize: '40px',
    fontWeight: '400',
  },
  pickCameraText: {
    textAlign: 'center',
    margin: 0,
    // fontSize: 'x-large',
    fontWeight: '300',
  },
  listText: {
    margin: 0,
    fontSize: '20px',
    fontWeight: '300',
  },
  noMargin: {
    margin: '7px',
  },
  noBottomMargin: {
    marginBottom: '0',
  },
  btnContainer: {
    // height: '60px',
    marginTop: '1rem',
  },
  videoContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    // margin: '1rem 1rem 1rem 3rem',
    position: 'relative',
    display: 'flex',
    height: '230px',
    width: '307px',
    backgroundColor: 'black',
  },
  videoTag: {
    height: '100%',
    width: '100%',
    border: 'solid 4px #565656',
  },
});

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    height: '25px',
    fontSize: '17px',
    borderRadius: 4,
    position: 'relative',
    border: '1px solid #97979761',
    background: 'transparent',
    padding: '10px 26px 10px 12px',
    color: '#000000',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      background: 'transparent',
    },
  },
}))(InputBase);

const useStyles = makeStyles(() => ({
  margin: {
    margin: '0',
  },
  selectDropdown: {
    border: '2px solid red',
    width: '500px',
  },
}));

// ssd_mobilenetv1 options
const DEFAULT_MIN_CONFIDENCE = 0.6;

export const VideoInputSelect = (props) => {
  const classes = useStyles();

  return (
    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
      <FormControl className={classes.margin}>
        <NativeSelect
          id="demo-customized-select-native"
          value={props.value || undefined}
          onChange={(event) => props.onChange(event.target.value)}
          className={styles.selectDropdown}
          input={<BootstrapInput />}
        >
          {_.map(props.webcams, (dev, index) => (
            <option key={dev.deviceId || index} value={dev.deviceId}>
              {dev.label || dev.deviceId}
            </option>
          ))}
        </NativeSelect>
      </FormControl>
    </div>
  );
};

export function getUserMedia(constraints) {
  if (navigator.mediaDevices?.getUserMedia) {
    return navigator.mediaDevices.getUserMedia(constraints);
  }

  const getUserMedia =
    navigator.getUserMedia ||
    navigator.webkitGetUserMedia ||
    navigator.mozGetUserMedia ||
    navigator.msGetUserMedia ||
    navigator.oGetUserMedia;

  if (getUserMedia) {
    return new Promise((resolve, reject) =>
      getUserMedia(constraints, resolve, reject)
    );
  }

  this.props.onFail();

  return Promise.reject(new Error('Browser is too old for webcam access'));
}

class WebcamStep extends React.PureComponent {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    this.videoRef = React.createRef();
    this.webcamRef = React.createRef();
    this.state = {
      titleMessage: 'Loading webcam...',
      hideCanvas: false,
      loaded: false,
      showWebcamError: false,
      noFaceDetection: false,
      successful: false,
      deviceId: null,
      showWebcamErrorTimer: setTimeout(() => null),
      accuracyCounter: 0,
    };
  }

  componentDidMount() {
    if (this.props.flags?.bypassWebcamCheck) {
      const { handleNext } = this.props;

      handleNext({ target: this.webcamRef.current });
    }

    this.resetCamera();
  }

  // eslint-disable-next-line class-methods-use-this
  getFaceDetectorOptions() {
    const minConfidence = this.props.flags?.minConfidence
      ? this.props.flags.minConfidence / 100
      : DEFAULT_MIN_CONFIDENCE;

    return new faceapi.SsdMobilenetv1Options({ minConfidence });
  }

  getCurrentFaceDetectionNet = () => {
    return faceapi.nets.ssdMobilenetv1;
  };

  isFaceDetectionModelLoaded = () => {
    return (
      this.state.noFaceDetection && !!this.getCurrentFaceDetectionNet().params
    );
  };

  onPlay = async () => {
    if (this.state.noFaceDetection) {
      return;
    }

    const { hideCanvas, titleMessage } = this.state;
    let { accuracyCounter } = this.state;
    const notFaceDetectedMessage =
      'No face detected, please uncover the camera or move in frame.';
    const doNotMoveMessage = 'Please do not move, system is reading your face.';
    const video = this.videoRef.current;
    const options = this.getFaceDetectorOptions();
    const setState = (newState) =>
      new Promise((resolve) => this.setState(newState, resolve));
    let loadFaceDetection;

    const processFaceDetection = async (result) => {
      try {
        if (result) {
          await setState((state) => ({
            accuracyCounter: state.accuracyCounter + 1,
          }));
          accuracyCounter += 1;

          if (titleMessage === notFaceDetectedMessage) {
            this.setState({
              titleMessage: 'Please do not move, system is reading your face. ',
            });
          }

          if (hideCanvas) {
            this.setState({ hideCanvas: false });
          }

          const canvas = this.canvasRef.current;
          const dims = faceapi.matchDimensions(canvas, video, true);
          const detections = faceapi.resizeResults(result, dims);

          if (detections && detections.box) {
            const {
              box: { x, y, width, height },
            } = detections;
            const customBox = { x, y, width, height };
            const drawOptions = {
              label: '',
              lineWidth: 2,
              boxColor: '#006F83',
            };
            const drawBox = new faceapi.draw.DrawBox(customBox, drawOptions);

            if (this.props.flags?.drawBox) {
              drawBox.draw(canvas);
            }
          }
        }

        if (accuracyCounter >= 6) {
          this.setState({ successful: true });
          // this.stopWebcamStream();
        }

        if (!result) {
          this.setState((state) => {
            if (accuracyCounter > 0) {
              return {
                accuracyCounter: state.accuracyCounter - 1,
                titleMessage: notFaceDetectedMessage,
              };
            }

            return {};
          });

          if (!hideCanvas) {
            this.setState({ hideCanvas: true });
          }
        }

        window.requestAnimationFrame(loadFaceDetection);
      } catch (err) {
        this.props.onFail(err);
      }

      return true;
    };

    loadFaceDetection = async () => {
      const result = await faceapi.detectSingleFace(video, options);

      if (!result && this.state.loaded) {
        if (this.state.titleMessage === 'Loading face recognition...') {
          this.setState({ titleMessage: notFaceDetectedMessage });
        }

        window.requestAnimationFrame(loadFaceDetection);

        return false;
      }

      this.setState({ titleMessage: doNotMoveMessage }, () =>
        processFaceDetection(result)
      );

      return true;
    };

    this.setState(
      { titleMessage: 'Loading face recognition...', loaded: true },
      loadFaceDetection
    );
  };

  videoError = (error) => {
    this.props.onFail(error);
  };

  getCanvasStyles = () => {
    const { drawCanvasOnWebcamFace } = this.props;
    let canvasStyles = {
      position: 'absolute',
      top: '0px',
      left: '0px',
      right: '0px',
      display: 'block',
      width: '100%',
      height: '100%',
    };

    if (!drawCanvasOnWebcamFace) {
      return {};
    }

    if (this.state.hideCanvas) {
      canvasStyles = { ...canvasStyles, display: 'none' };
    }

    return canvasStyles;
  };

  handleVideo = (stream) => {
    const video = this.videoRef.current;

    if (video) {
      video.srcObject = stream;

      video.play();
    }
  };

  initFaceRecognitionApi = async () => {
    const { modelsUrl, webcamDevices } = this.props;

    await Promise.all([
      faceapi.loadSsdMobilenetv1Model(modelsUrl),
      faceapi.loadFaceLandmarkModel(modelsUrl),
      faceapi.loadFaceRecognitionModel(modelsUrl),
    ])
      .catch(() =>
        this.setState({ noFaceDetection: true, hideCanvas: true, loaded: true })
      )
      .then(async () => {
        if (!webcamDevices) {
          this.setState({
            titleMessage:
              'No camera devices found. Please continue on a device with a camera.',
          });

          return true;
        }

        this.handleVideo(
          await getUserMedia({ video: { deviceId: this.state.deviceId } })
        );

        return true;
      })
      .catch(this.videoError);
  };

  stopWebcamStream = (newState) => {
    const video = this.videoRef.current;
    const { handleNext, webcamDevices } = this.props;
    const stream = video.srcObject;

    clearTimeout(this.state.showWebcamErrorTimer);

    if (!video || !stream) {
      return;
    }

    setTimeout(() => {
      stream.getTracks().forEach((track) => {
        track.stop();
      });

      if (newState === 'successful') {
        handleNext({ target: this.webcamRef.current });
      } else if (newState === 'next cam') {
        this.setState(
          (state) => {
            const currentDeviceIdIndex = webcamDevices.findIndex(
              ({ deviceId }) => deviceId === state.deviceId
            );
            const nextDeviceIndex =
              (currentDeviceIdIndex + 1) % webcamDevices.length;

            return {
              deviceId: webcamDevices[nextDeviceIndex]?.deviceId,
              loaded: false,
            };
          },
          () => this.resetCamera()
        );
      }
    }, 2000);
  };

  resetCamera() {
    this.stopWebcamStream();
    this.accuracyCounter = 0;
    this.setState(
      (state) => ({
        successful: false,
        titleMessage: 'Loading webcam...',
        hideCanvas: true,
        loaded: false,
        showWebcamError: false,
        deviceId: state.deviceId || this.props.webcamDevices[0]?.deviceId,
      }),
      async () => {
        await this.initFaceRecognitionApi();
        this.setState({
          showWebcamErrorTimer: setTimeout(() => {
            this.setState({ showWebcamError: true });

            // eslint-disable-next-line react/no-access-state-in-setstate
            if (!this.state.successful) {
              this.props.onFail();
            }
          }, 5000),
        });
      }
    );
  }

  render() {
    const { loaded, noFaceDetection } = this.state;
    const { webcamDevices } = this.props;
    const flags = this.props.flags;
    const canvasStyles = this.getCanvasStyles();
    const { classes } = this.props;

    return (
      <Grid
          container
          item
          alignContent="space-between"
          style={{ height: '100%' }}
        >
          {/* top half */}
          <Grid
            container
          >
            <Grid
              container
              item
              xs={this.props.screenSize === 'small' ? 12 : 6}
              style={{ padding: '1rem' }}
            >
              <Grid item xs={12} style={{ marginBottom: '-1.5rem' }}>
                <h2 className={classes.headerStyle}>
                  {noFaceDetection || flags?.disableFacialRecognition
                    ? 'CAN YOU SEE YOURSELF?'
                    : 'CAMERA CHECK'}
                </h2>
              </Grid>
              <Grid item xs={12}>
                <p className={classes.pickCameraText}>
                  Pick the camera you will be using for the notarization.
                </p>
              </Grid>
              <Grid container item xs={12} justify="center">
                <VideoInputSelect
                  webcams={webcamDevices}
                  value={this.state.deviceId}
                  onChange={(deviceId) =>
                    this.setState({ deviceId }, () => this.resetCamera())
                  }
                />
              </Grid>
            </Grid>

            <Grid
              container
              item
              xs={this.props.screenSize === 'small' ? 12 : 6}
              style={{ padding: '1rem' }}
              justify="center"
              alignItems="center"
            >
              <div ref={this.webcamRef} className={classes.videoContainer}>
                <video
                  ref={this.videoRef}
                  onLoadedMetadata={this.onPlay}
                  id="inputVideo"
                  muted
                  className={classes.videoTag}
                />
                <canvas
                  id="canvasInput"
                  ref={this.canvasRef}
                  style={canvasStyles}
                />
                {!loaded && <Loader />}
              </div>
            </Grid>
          </Grid>

          {/* bottom half */}
          <Grid
            item
            xs={12}
            style={{
              height: '4%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Divider
              variant="middle"
              style={{
                height: '2px',
                width: '96%',
                backgroundColor: '#97979761',
              }}
            />
          </Grid>

          {/*  */}
          <div style={{ height: '48%', flex: '1', display: 'flex' }}>
            {noFaceDetection || flags?.disableFacialRecognition ? (
              <Grid style={{ display: 'block' }} item xs={12}>
                <Grid item xs={12}>
                  <p className={classes.headerStyle}>
                    DO YOU SEE YOURSELF ABOVE?
                  </p>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    padding: '0 0 2rem 0',
                  }}
                >
                  <ReusableButton
                    onClick={() => this.stopWebcamStream('next cam')}
                    type="button"
                    variant="contained"
                    theme={this.props.theme}
                    data-trigger="sound-test"
                    endIcon={<NotInterestedIcon />}
                  >
                    No
                  </ReusableButton>

                  <ReusableButton
                    onClick={() => this.stopWebcamStream('successful')}
                    type="button"
                    variant="contained"
                    color="primary"
                    theme={this.props.theme}
                    data-trigger="sound-test"
                    endIcon={<ArrowForwardIosIcon />}
                  >
                    Yes
                  </ReusableButton>
                </Grid>
              </Grid>
            ) : (
              <>
                {(!this.state.loaded || !this.state.showWebcamError) && (
                  <Grid
                    item
                    container
                    style={{ padding: '3rem' }}
                    justify="center"
                  >
                    <Grid container item justify="center" xs={12}>
                      <h2
                        className={classes.headerStyle}
                        style={{ margin: '0' }}
                      >
                        Hints
                      </h2>
                    </Grid>
                    <ol className={classes.noBottomMargin}>
                      <li className={classes.listText}>
                        Select web camera you will use for the notarization.
                      </li>
                      <li className={classes.listText}>
                        Make sure the web camera is not blocked.
                      </li>
                      <li className={classes.listText}>
                        Stay still and smile while we confirm you are in camera.
                      </li>
                    </ol>
                  </Grid>
                )}
                {this.state.loaded &&
                  !this.state.successful &&
                  this.state.showWebcamError && (
                    <Grid
                      container
                      style={{ padding: '0 3rem', width: '100%' }}
                      justify="space-between"
                    >
                      <Grid item xs={12} container alignItems="center">
                        <h2
                          className={classes.headerStyle}
                          style={{ margin: '0', textAlign: 'left' }}
                        >
                          Oh no!
                        </h2>
                      </Grid>
                      <Grid item>
                        <p
                          className={`${classes.noMargin} ${classes.listText}`}
                        >
                          We cannot see you! Make sure:
                        </p>
                        <Grid item xs={12}>
                          <ul className={classes.noMargin}>
                            <li className={classes.listText}>
                              You are in the view of your camera.
                            </li>
                            <li className={classes.listText}>
                              You are staying still during the camera check.
                            </li>
                            <li className={classes.listText}>
                              Your browser has not blocked eNotaryLog.com from
                              accessing your camera.
                            </li>
                          </ul>
                        </Grid>
                        <p
                          className={`${classes.noMargin} ${classes.listText}`}
                        >
                          If these do not work, try to select a different camera
                          on your device.
                        </p>
                      </Grid>
                    </Grid>
                  )}
              </>
            )}

            {this.state.successful &&
              !flags?.disableFacialRecognition &&
              this.state.showWebcamError && (
                <Grid container justify="space-evenly" alignItems="center">
                  <Grid
                    container
                    alignItems="center"
                    item
                    xs={6}
                    style={{
                      textAlign: 'center',
                      height: '100%',
                      width: '50%',
                      padding: '1rem',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    <Grid item>
                      <h2
                        className={classes.headerStyle}
                        style={{ margin: '0' }}
                      >
                        We see you!
                      </h2>
                    </Grid>
                    <Grid item className={classes.btnContainer}>
                      <ReusableButton
                        onClick={() => this.stopWebcamStream('successful')}
                        type="button"
                        variant="contained"
                        color="primary"
                        theme={this.props.theme}
                        data-trigger="sound-test"
                        endIcon={<ArrowForwardIosIcon />}
                      >
                        Keep going!
                      </ReusableButton>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    alignItems="center"
                    style={{ height: '100%', width: '50%', padding: '1rem' }}
                    justify="center"
                    xs={6}
                  >
                    <VerficationDefaultIcon
                      length={150}
                      theme={this.props.theme}
                      animation="middle-out"
                    />
                  </Grid>
                </Grid>
              )}
          </div>
        </Grid>
    );
  }
}

WebcamStep.propTypes = {
  modelsUrl: PropTypes.string.isRequired,
  drawCanvasOnWebcamFace: PropTypes.bool,
  handleNext: PropTypes.func.isRequired,
  // isActive: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  flags: PropTypes.object.isRequired,
  webcamDevices: PropTypes.arrayOf(PropTypes.object),
};

WebcamStep.defaultProps = {
  drawCanvasOnWebcamFace: true,
  // isActive: false, TODO: Find out about variable
  webcamDevices: [],
};

const Webcam = withStyles(styles)(WebcamStep);

export default function WithMediaQuery(props) {
  const largeScreen = useMediaQuery('(min-width:870px)');
  const mediumScreen = useMediaQuery('(min-width:600px)');

  let screenSize = 'small';

  if (largeScreen) {
    screenSize = 'large';
  } else if (mediumScreen) {
    screenSize = 'medium';
  }

  return <Webcam {...props} screenSize={screenSize} />;
}
