import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import tabSvg from './icons/tabSvg';

const useStyles = makeStyles(() => ({
  small: {},
  medium: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100px',
  },
  large: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    fontWeight: '500',
    fontSize: '20px',
  },
  textLarge: {
    marginLeft: '1rem',
  },
}));

export default function TabStepSvg({ step, completed, theme }) {
  const largeScreen = useMediaQuery('(min-width:870px)');
  const mediumScreen = useMediaQuery('(min-width:600px)');

  let screenSize = 'small';

  if (largeScreen) {
    screenSize = 'large';
  } else if (mediumScreen) {
    screenSize = 'medium';
  }

  const classes = useStyles();

  return (
    <>
      <Grid className={classes[screenSize]}>
        <Grid>{tabSvg({ step, completed, theme })}</Grid>
        <Grid
          item
          className={screenSize === 'large' ? classes.textLarge : ''}
          // style={{ marginLeft: '1rem' }}
        >
          <h5 className={classes[screenSize]}>{step}</h5>
        </Grid>
      </Grid>
    </>
  );
}
