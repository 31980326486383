/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles } from '@material-ui/core';
import VerficationDefaultIcon from '../equipment-check/icons/verificationDefaultIcon';
import MusicNotesIcon from '../equipment-check/icons/MusicNotesIcon';
import ReusableButton from './reusableButton';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import RefreshIcon from '@material-ui/icons/Refresh';
import InstrumentSvg from './icons/instrumentSvg';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
  headerStyle: {
    textAlign: 'center',
    color: theme.headerTextColor,
    fontSize: '40px',
    fontWeight: '400',
    // whiteSpace: 'nowrap',
  },
  headerContainer: {
    height: '100px',
  },
  pStyle: {
    textAlign: 'center',
    marginTop: '1rem',
    height: '40px',
    fontSize: 'x-large',
    fontWeight: '300',
  },
  radio: {
    '&$checked': {
      color: '#4B8DF8',
    },
  },
  checked: {},
  audioPanel: {},
}));

const CustomRadio = withStyles((theme) => ({
  root: {
    //color: theme.primaryColor,
    '&$checked': {
      //color: theme.primaryColor,
    },
  },
  checked: {},
}))((props) => <Radio color="default" {...props} />);

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    height: '25px',
    fontSize: '17px',
    width: '250px',
    borderRadius: 4,
    position: 'relative',
    background: 'transparent',
    border: '1px solid #97979761',
    padding: '10px 26px 10px 12px',
    color: '#000000',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      background: 'transparent',
    },
  },
}))(InputBase);

const AudioOutputSelect = (props) => {
  const classes = useStyles();

  return (
    <Grid container item justify="center">
      <FormControl>
        <NativeSelect
          id="demo-customized-select-native"
          disabled={props.disabled}
          onChange={props.onChange}
          input={<BootstrapInput />}
        >
          {_.map(props.devices, (dev, index) => (
            <option key={dev.deviceId || index} value={dev.deviceId}>
              {dev.label || dev.deviceId}
            </option>
          ))}
        </NativeSelect>
      </FormControl>
    </Grid>
  );
};

/**
 *
 *
 * @param {*} props
 * @returns
 */
function AudioCheck(props) {
  const defaultRadioButtonOnChangeDelay = 500; //in ms
  const audioRef = useRef();
  const { isActive } = props;
  const [showAudioConfirmMessage, setShowAudioConfirmMessage] = useState(false);
  const [componentHeader, setComponentHeader] = useState(
    'What sound do you hear?'
  );
  const [, setAudioTrack] = useState();
  const [selectedMp3, setSelectedMp3] = useState({});
  const [value, setValue] = useState();
  const [quizCompleted, setQuizCompleted] = useState(null);
  const audioArray = [
    {
      label: 'Horns',
      value: 'horns',
      src: props.cdnUrl + '/static/sounds/horns.wav',
    },
    {
      label: 'Drums',
      value: 'drums',
      src: props.cdnUrl + '/static/sounds/drums.wav',
    },
    {
      label: 'Piano',
      value: 'piano',
      src: props.cdnUrl + '/static/sounds/piano.mp3',
    },
  ];

  const handleAudioCheck = async (evt) => {
    evt.preventDefault();
    //TODO random number between 0 and 3
    const randomIndex = Math.floor(
      Math.random() * Math.floor(audioArray.length)
    );
    setSelectedMp3(audioArray[randomIndex]);
    const audio = audioRef.current;

    await audio.load();
    audio.play();

    audio.onplaying = () => {
      setAudioTrack(audio);
      setShowAudioConfirmMessage(true);
    };
  };

  const handleAudioOutputChange = async (evt) => {
    reset();
    if (audioRef && audioRef.current) {
      if (!audioRef.current.paused) {
        audioRef.current.pause();

        if (_.isFunction(audioRef.current.setSinkId)) {
          await audioRef.current.setSinkId(evt.target.value);
        }

        return audioRef.current.play();
      }

      if (_.isFunction(audioRef.current.setSinkId)) {
        await audioRef.current.setSinkId(evt.target.value);
      }
    }
  };

  const reset = async () => {
    const audio = audioRef.current;

    if (audio) {
      audio.pause();
    }

    setAudioTrack(null);
    setShowAudioConfirmMessage(false);
    setComponentHeader('What sound do you hear?');
    setValue(null);
    setQuizCompleted(null);
    await setShowAudioConfirmMessage(false);
  };

  useEffect((evt) => {
    if (props.flags?.bypassAudioCheck) {
      props.handleNext(evt, 'complete');
    }
  }, []);

  useEffect(() => {
    if (!isActive) {
      reset();
    }
  }, [isActive]);

  const handleChange = (event) => {
    setValue(event.target.value);
    event.persist();
    setTimeout(() => {
      //if value correct do pass
      const audio = audioRef.current;

      if (audio) {
        audio.pause();
      }

      if (event.target.value === selectedMp3.value) {
        setComponentHeader('Sounds like you hear us!');
        setQuizCompleted('pass');
      } else {
        setQuizCompleted('fail');
        setComponentHeader('Oops!');
        props.onFail();
      }
    }, props.flags?.radioButtonOnChangeDelay || defaultRadioButtonOnChangeDelay);
  };

  const classes = useStyles();
  const InstrumentIcon = InstrumentSvg[selectedMp3.value];

  return (
    <Grid container item alignItems="center" style={{ height: '100%' }}>
      <>
        <Grid
          item
          xs={12}
          style={
            showAudioConfirmMessage
              ? { height: '48%', width: '835px' }
              : { height: '100%', paddingTop: '6rem', paddingBottom: '6rem', width: '835px' }
          }
        >
          <Grid item>
            <h2 className={classes.headerStyle}>CAN YOU HEAR US?</h2>
          </Grid>
          <Grid item>
            <p className={classes.pStyle}>
              Pick the speakers you will be using for the notarization.
            </p>
          </Grid>
          {!(props.isMobile === true && props.isSafari === true) && (
            <AudioOutputSelect
              devices={props.audioDevices}
              disabled={!audioRef && !audioRef.current}
              onChange={handleAudioOutputChange}
            />
          )}

          {!showAudioConfirmMessage && (
            <Grid
              xs={12}
              className={classes.btnContainer}
              style={{ marginTop: '3rem' }}
              container
              justify="center"
            >
              <Grid container justify="center" item xs={4}>
                {' '}
                {/*TODO: is this correct logic? -Ken */}
                <ReusableButton
                  onClick={handleAudioCheck}
                  type="button"
                  variant="contained"
                  color="primary"
                  theme={props.theme}
                  className={classes.button}
                  data-trigger="sound-test"
                  endIcon={<ArrowForwardIosIcon />}
                >
                  Start Test
                </ReusableButton>
              </Grid>
            </Grid>
          )}
        </Grid>
      </>
      {showAudioConfirmMessage && (
        <Grid
          item
          xs={12}
          style={{
            height: '4%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Divider
            variant="middle"
            style={{
              height: '2px',
              width: '96%',
              backgroundColor: '#97979761',
            }}
          />
        </Grid>
      )}

      {showAudioConfirmMessage && (
        <Grid
          container
          item
          alignItems="center"
          xs={12}
          style={{ height: '10%', justifyContent: 'center' }}
        >
          <h2
            className={classes.headerStyle}
            style={{ margin: '0', paddingTop: '2rem' }}
          >
            {componentHeader}
          </h2>
        </Grid>
      )}
      {showAudioConfirmMessage && (
        <Grid
          container
          item
          className={quizCompleted === 'pass' ? classes.audioPanel : ''}
          style={{ height: '38%' }}
        >
          <Grid
            xs={
              quizCompleted === 'fail' ? 12 : quizCompleted === 'pass' ? 12 : 6
            }
            item
            style={
              showAudioConfirmMessage === true
                ? {
                  height: '100%',
                  padding: '2rem',
                  display: 'flex',
                  alignItems: 'center',
                }
                : { display: 'none' }
            }
          >
            {quizCompleted === 'pass' && (
              <Grid
                container
                item
                xs={6}
                style={{
                  height: '100%',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  padding: '1.5rem 5.5rem 1.5rem 1.5rem',
                }}
              >
                {value && quizCompleted === 'pass' && <InstrumentIcon />}
              </Grid>
            )}
            {!quizCompleted && (
              <Grid
                container
                item
                xs={12}
                style={{ justifyContent: 'flex-end' }}
              >
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="sound"
                    name="sound"
                    value={value}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="drums"
                      control={<CustomRadio />}
                      label="Drum"
                    />
                    <FormControlLabel
                      value="horns"
                      control={<CustomRadio />}
                      label="Horn"
                    />
                    <FormControlLabel
                      value="piano"
                      control={<CustomRadio />}
                      label="Piano"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            )}

            {/* 
              { quizCompleted === 'fail' && (<Grid item>
                    <p className={classes.pStyle}>Pick the speakers you will be using for the notarization.</p>
                   </Grid>)} */}
            {quizCompleted === 'fail' && (
              <Grid
                container
                justify="center"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  paddingBottom: '2rem',
                }}
                className={classes.btnContainer}
              >
                <Grid item>
                  <p className={classes.pStyle}>You picked the wrong sound.</p>
                </Grid>
                <ReusableButton
                  onClick={async (evt) => {
                    evt.preventDefault();
                    audioRef.current.pause();
                    setComponentHeader('What sound do you hear?');
                    setValue(null);
                    setQuizCompleted(null);
                    await setShowAudioConfirmMessage(false);
                  }}
                  type="button"
                  variant="contained"
                  color="primary"
                  theme={props.theme}
                  data-trigger="sound-test"
                  endIcon={<RefreshIcon />}
                >
                  Try Again
                </ReusableButton>
              </Grid>
            )}

            {quizCompleted === 'pass' && (
              <Grid
                container
                item
                xs={6}
                justify="center"
                style={{
                  height: '100%',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  padding: '1.5rem 4rem 1.5rem 0rem',
                }}
              >
                <ReusableButton
                  onClick={(evt) => {
                    evt.preventDefault();
                    audioRef.current.pause();

                    return props.handleNext(evt, 'mic');
                  }}
                  type="button"
                  variant="contained"
                  theme={props.theme}
                  color="primary"
                  data-trigger="sound-test"
                  endIcon={<ArrowForwardIosIcon />}
                >
                  Keep Going
                </ReusableButton>
              </Grid>
            )}
          </Grid>
          {!quizCompleted && (
            <Grid
              container
              alignItems="center"
              item
              xs={6}
              style={
                showAudioConfirmMessage === true
                  ? {
                    display: 'flex',
                    padding: '2rem',
                    justifyContent: 'flex-start',
                  }
                  : { display: 'none' }
              }
            >
              <MusicNotesIcon length={120} animation={'shake-lr'} />
            </Grid>
          )}
          {/* { quizCompleted === 'pass' && (
          <Grid container item xs={6} style={(showAudioConfirmMessage === true) ? { display: 'flex', alignItems: 'center' } : { display: 'none' }}>
            <VerficationDefaultIcon length={120}/>
          </Grid>
          )} */}
        </Grid>
      )}
      <audio ref={audioRef} loop={true}>
        <source src={selectedMp3.src} type="audio/mpeg" />
      </audio>
    </Grid>
  );
}

export default React.forwardRef((props, ref) => (
  <AudioCheck {...props} forwardedRef={ref} />
));
