/* eslint-disable no-tabs */
/* eslint-disable max-len */
import { makeStyles } from '@material-ui/core';
import Color from 'color';

const useStyle = makeStyles((theme) => {
  const color = Color(theme.iconColor);
  const lightness = color.lightness();

  const iconColorLuminosity = color.luminosity();

  let brighterColor;
  let darkerColor;

  if (iconColorLuminosity <= 0.5) {
    brighterColor = color.lightness(lightness + 25).hex();
    darkerColor = color.lightness(lightness - 15).hex();
  } else {
    darkerColor = color.lightness(lightness - 30).hex();
    brighterColor = color.lightness(lightness + 30).hex();
  }

  const fill = color.isLight() ? darkerColor : brighterColor;

  return {
    'shake-lr': {
      animation:
        'shake-lr 2s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both',
      animationName: '$shake-lr',
    },
    '@keyframes shake-lr': {
      '0%': {},
      '100%': {
        transform: 'rotate(0deg)',
        transformOrigin: '50% 50%',
      },
      '10%': {
        transform: 'rotate(8deg)',
      },
      '20%': {},
      '40%': {},
      '60%': {
        transform: 'rotate(-10deg)',
      },
      '30%': {},
      '50%': {},
      '70%': {
        transform: 'rotate(10deg)',
      },
      '80%': {
        transform: 'rotate(-8deg)',
      },
      '90%': {
        transform: 'rotate(8deg)',
      },
    },
    iconColor: {
      fill: theme.iconColor,
    },
    iconHighlightColor: {
      fill,
    },
  };
});

export default ({ length, animation = 'none' }) => {
  const classes = useStyle();

  return (
    <div className={classes[animation]}>
      <svg
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width={length}
        height={length}
        viewBox="0 0 512 512"
        style={{ enableBackground: 'new 0 0 512 512' }}
        xmlSpace="preserve"
      >
        <style type="text/css" />
        <path
          className={classes.iconColor}
          d="M272,437c0,8.3,6.7,15,15,15s15-6.7,15-15V268.3l-13.7-26.1L272,276.9V437z"
        />
        <path
          className={classes.iconHighlightColor}
          d="M482,377c0,8.3,6.7,15,15,15s15-6.7,15-15V208.3l-14.3-25.9L482,216.9V377z"
        />
        <path
          className={classes.iconHighlightColor}
          d="M242,167c0,41.4-33.6,75-75,75c-8.4,0-15-6.6-15-15c0-8.4,6.6-15,15-15c24.9,0,45-20.1,45-45
	        c0-14.4-6.6-27.6-18.3-36.3c-24-17.4-51.4-39.7-72.7-66.4V302c0,8.4-6.6,15-15,15s-15-6.6-15-15V15c0-6.6,4.2-12.3,10.5-14.4
	        c1.5-0.3,3-0.6,4.5-0.6c4.8,0,9.6,2.4,12.6,6.6l17.1,26.1c19.8,29.4,48.7,53.9,75.7,73.7C230.6,120.5,242,143.3,242,167z"
        />
        <path
          className={classes.iconColor}
          d="M242,167c0,41.4-33.6,75-75,75c-8.4,0-15-6.6-15-15c0-8.4,6.6-15,15-15c24.9,0,45-20.1,45-45
	c0-14.4-6.6-27.6-18.3-36.3c-24-17.4-51.4-39.7-72.7-66.4V302c0,8.4-6.6,15-15,15V0c4.8,0,9.6,2.4,12.6,6.6l17.1,26.1
	c19.8,29.4,48.7,53.9,75.7,73.7C230.6,120.5,242,143.3,242,167z"
        />
        <path
          className={classes.iconHighlightColor}
          d="M242,512c-33.1,0-60-26.9-60-60s26.9-60,60-60s60,26.9,60,60S275.1,512,242,512z"
        />
        <path
          className={classes.iconHighlightColor}
          d="M106,262.7C95.2,250.1,79,242,61,242c-33,0-61,27-61,60c0,33,28,60,61,60c18,0,34.2-8.1,45-20.7
	c9.6-10.5,15-24.3,15-39.3S115.6,273.2,106,262.7z"
        />
        <path
          className={classes.iconColor}
          d="M452,452c-33.1,0-60-26.9-60-60s26.9-60,60-60s60,26.9,60,60S485.1,452,452,452z"
        />
        <path
          className={classes.iconColor}
          d="M121,302c0,15-5.4,28.8-15,39.3v-78.6C115.6,273.2,121,287,121,302z"
        />
        <path
          className={classes.iconHighlightColor}
          d="M506,125c-3.6-2.7-8.7-3.6-13.2-2.4L392,151.4l-109.2,31.2c-6.3,1.8-10.8,7.8-10.8,14.4v79.8l30-8.4l90-25.8
	l90-25.8l30-8.4V137C512,132.2,509.9,128,506,125z"
        />
        <path
          className={classes.iconColor}
          d="M512,137v71.4l-30,8.4l-90,25.8v-91.2l100.8-28.8c4.5-1.2,9.6-0.3,13.2,2.4C509.9,128,512,132.2,512,137z"
        />
      </svg>
    </div>
  );
};
