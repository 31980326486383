import { makeStyles } from '@material-ui/core';
import Color from 'color';

const useStyles = makeStyles((theme) => {
  const color = Color(theme.primaryColor);
  const lightness = color.lightness();
  const iconColorLuminosity = color.luminosity();

  let brighterColor;
  let darkerColor;

  if (iconColorLuminosity <= 0.5) {
    brighterColor = color.lightness(lightness + 10).hex();
    darkerColor = color.lightness(lightness - 15).hex();
  } else if (iconColorLuminosity <= 0.2) {
    brighterColor = color.lightness(lightness + 10).hex();
    darkerColor = color.lightness(lightness - 15).hex();
  } else {
    darkerColor = color.lightness(lightness - 25).hex();
    brighterColor = color.lightness(lightness + 30).hex();
  }

  const fill = color.isLight() ? darkerColor : brighterColor;

  return {
    'middle-out': {
      animation:
        'scaleUpHorCenter 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both',
      animationName: '$scaleUpHorCenter',
    },
    '@keyframes scaleUpHorCenter': {
      '0%': {
        transform: 'scaleX(0.4)',
      },
      '100%': {
        transform: 'scaleX(1)',
      },
    },
    'shake-vertical': {
      animation:
        'shake-vertical 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both',
      animationName: '$shake-vertical',
    },
    '@keyframes shake-vertical': {
      '0%': {},
      '100%': {
        transform: 'translateY(0)',
      },
      '10%': {},
      '30%': {},
      '50%': {},
      '70%': {
        transform: 'translateY(-8px)',
      },
      '20%': {},
      '40%': {},
      '60%': {
        transform: 'translateY(8px)',
      },
      '80%': {
        transform: 'translateY(6.4px)',
      },
      '90%': {
        transform: 'translateY(-6.4px)',
      },
    },
    iconColor: {
      fill: color.hex(),
    },
    iconHighlightColor: {
      fill,
    },
  };
});

export default ({ length, visibility, animation = 'none' }) => {
  const classes = useStyles();

  return (
    <div className={classes[animation]}>
      <svg
        version="1.1"
        id="Layer_1"
        width={length}
        height={length}
        visibility={visibility}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 511 511"
        style={{ enableBackground: 'new 0 0 511 511' }}
        xmlSpace="preserve"
      >
        <style
          type="text/css"
          dangerouslySetInnerHTML={{
            __html:
              '\n\t.st00{fill:#00DD80;}\n\t.st11{fill:#00AA63;}\n\t.st22{fill:#FFFFFF;}\n\t.st33{fill:#E1EBF0;}\n\t.st44{fill:#004FAC;}\n',
          }}
        />
        <path
          className={classes.iconHighlightColor}
          d="M472.4,341.2c-15.3,37.2-38.6,69.6-68.9,96.2c-34.6,30.3-80,54.3-134.9,71.5c-1.8,0.6-3.7,1-5.6,1.4
c-2.5,0.4-5,0.7-7.5,0.7H255c-2.7,0-5.4-0.2-8-0.7c-1.9-0.3-3.7-0.8-5.5-1.3c-54.9-17.1-100.3-41.2-135-71.5
c-30.5-26.6-53.6-58.9-68.9-96.2c-27.8-67.7-26.3-142.2-25-202.2v-0.9c0.2-4.9,0.4-10.1,0.6-15.9c0.4-28.2,25.5-51.5,57-53.1
C135.6,66,186.3,46.9,229.7,9l0.3-0.3c7.2-5.9,16.3-8.8,25.4-8.7c8.7,0.1,17.4,3,24.4,8.7l0.3,0.3c43.4,37.9,94.2,57,159.8,60.3
c31.5,1.6,56.5,24.9,57,53.1c0.1,5.8,0.2,11,0.6,15.9v0.4C498.7,198.7,500.3,273.4,472.4,341.2z"
        />
        <path
          className={classes.iconColor}
          d="M472.5,341.2c-15.2,37.2-38.3,69.6-68.5,96.2c-34.4,30.3-79.5,54.3-134,71.5c-1.8,0.6-3.7,1-5.6,1.4
c-2.4,0.4-5,0.7-7.4,0.7V0c8.7,0.1,17.3,3,24.2,8.7l0.3,0.3c43.1,37.9,93.6,57,158.8,60.3c31.3,1.6,56.2,24.9,56.6,53.1
c0.1,5.8,0.2,11,0.6,15.9v0.4C498.7,198.7,500.2,273.4,472.5,341.2z"
        />
        <path
          className="st22"
          d="M383.9,255.5c0,70.1-56.9,127.1-126.9,127.4h-0.4c-70.2,0-127.4-57.1-127.4-127.4
c0-70.2,57.1-127.4,127.4-127.4h0.4C327,128.4,383.9,185.4,383.9,255.5z"
        />
        <path
          className="st33"
          d="M383.9,255.5c0,70.1-56.9,127.1-126.9,127.4V128.1C327,128.4,383.9,185.4,383.9,255.5z"
        />
        <path
          className="st44"
          d="M314.3,237.2l-57.4,57.4L244.5,307c-2.9,2.9-6.8,4.4-10.6,4.4s-7.7-1.5-10.6-4.4l-26.6-26.7
c-5.9-5.9-5.9-15.3,0-21.2c5.8-5.9,15.3-5.9,21.2,0l16.1,16.1l59.2-59.2c5.9-5.9,15.4-5.9,21.2,0
C320.2,221.8,320.2,231.3,314.3,237.2z"
        />
      </svg>
    </div>
  );
};
